import Router from 'next/router';

import { cookie, Nullable } from '@tager/web-core';

import { getTheme } from '@/constants/theme';
import {
  availablePagesDarkMode,
  availableTemplatesDarkMode,
} from '@/theme/constants';

import { ThemeColorsType, ThemeTypes } from './types';
import {
  b2bLightColors,
  hisunLightColors,
  jacLightColors,
  sollersLightColors,
  jetourLightColors,
  mainDarkColors,
  mainLightColors,
  zeekrLightColors,
} from './colors';

export const getInitialTheme = (): ThemeTypes => {
  const theme = getTheme();
  const cookieTheme = cookie.get('theme') as Nullable<ThemeTypes>;

  switch (theme) {
    case 'main':
      if (cookieTheme && isThemeVisible()) {
        return cookieTheme;
      }

      return 'mainLight';
    case 'b2b':
      return 'b2bLight';
    case 'hisun':
      return 'hisunLight';
    case 'jac':
      return 'jacLight';
    case 'sollers':
      return 'sollersLight';
    case 'jetour':
      return 'jetourLight';
    case 'zeekr':
      return 'zeekrLight';
    default:
      return 'mainLight';
  }
};

export const getNextTheme = (currentTheme: ThemeTypes): ThemeTypes => {
  const theme = getTheme();

  switch (theme) {
    case 'main':
      return currentTheme === 'mainLight' ? 'mainDark' : 'mainLight';
    case 'b2b':
      return 'b2bLight';
    case 'hisun':
      return 'hisunLight';
    case 'jac':
      return 'jacLight';
    case 'sollers':
      return 'sollersLight';
    case 'jetour':
      return 'jetourLight';
    case 'zeekr':
      return 'zeekrLight';
    default:
      return 'mainLight';
  }
};

export const isThemeVisible = (): boolean => {
  return (
    (process.env.NEXT_PUBLIC_SITE_DARK_THEME === '1' ||
      process.env.NEXT_PUBLIC_SITE_DARK_THEME === 'true') &&
    isThemeVisibleOnPage()
  );
};

export const getColorsByTheme = (theme: ThemeTypes): ThemeColorsType => {
  switch (theme) {
    case 'mainLight':
      return mainLightColors;
    case 'mainDark':
      return mainDarkColors;
    case 'b2bLight':
      return b2bLightColors;
    case 'hisunLight':
      return hisunLightColors;
    case 'jacLight':
      return jacLightColors;
    case 'sollersLight':
      return sollersLightColors;
    case 'jetourLight':
      return jetourLightColors;
    case 'zeekrLight':
      return zeekrLightColors;
    default:
      return mainLightColors;
  }
};

export const isThemeVisibleOnPage = (): boolean => {
  const pageProps =
    Router.router?.components?.[Router.router?.route].props?.pageProps;
  const pageType = pageProps?.pageType;
  const pageTemplate = pageProps?.template;

  return (
    availablePagesDarkMode.some((page) => page.includes(pageType)) ||
    availableTemplatesDarkMode.some((template) =>
      template.includes(pageTemplate)
    )
  );
};
