import React from 'react';
import styled, { css } from 'styled-components';

import { ScrollStyles } from '@/components/styles';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { LabelStaticStyles } from './TextArea.common.styles';
import { TextAreaProps } from './TextArea.types';

function TextArea({ label, errorMessage, ...props }: TextAreaProps) {
  return (
    <Component>
      <Area invalid={!!errorMessage} {...props} />
      {label && <Label>{label}</Label>}
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default TextArea;

const Component = styled.div`
  width: 100%;
  position: relative;
`;

const Label = styled.span`
  position: absolute;
  top: 14px;
  left: 16px;

  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.black100_alpha50};
  pointer-events: none;
  transition: all 0.2s;
`;

const Area = styled.textarea<{ invalid?: boolean }>`
  padding: 14px 16px;
  min-height: 144px;

  background: ${(props) => props.theme.white_1};
  border-radius: 5px;

  display: block;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black100};
  background-clip: padding-box;
  border: 1px solid ${(props) => props.theme.gray1800};
  appearance: none;
  resize: none;

  ${media.tablet(css`
    min-height: 122px;
  `)}

  ${ScrollStyles}

  &::placeholder {
    color: transparent;
    opacity: 1;
  }

  &:focus ~ ${Label} {
    ${LabelStaticStyles}
  }

  &:not(:placeholder-shown) ~ ${Label} {
    ${LabelStaticStyles}
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${colors.red700};
      background: ${(props) => props.theme.red_1};
    `}
`;

const Error = styled.div`
  position: absolute;
  color: ${colors.red600};
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  bottom: -18px;
`;
