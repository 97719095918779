import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';

interface State {
  isDifferentParams: boolean;
}

const initialState: State = {
  isDifferentParams: false,
};

const slice = createSlice({
  name: 'cars-stock-compare',
  initialState,
  reducers: {
    setDifferentParams: (state, action: PayloadAction<boolean>) => {
      state.isDifferentParams = action.payload;
    },
  },
});

export default slice.reducer;

export const { setDifferentParams } = slice.actions;

/** Selectors **/

export const getDifferentParams = (state: AppState): boolean => {
  return state.carsStockCompare.isDifferentParams;
};
