import { Roboto } from '@next/font/google';
import localFont from '@next/font/local';

import { ThemeValuesType } from '@/typings/common';

export const getTheme = (): ThemeValuesType => {
  return (process.env.NEXT_PUBLIC_THEME as ThemeValuesType) ?? 'main';
};

const colorsMain = {
  main: '#0061ed',
  main100: '#0556cc',

  white: '#fff',
  white_alpha20: 'rgba(255, 255, 255, 0.2)',
  white_alpha40: 'rgba(255, 255, 255, 0.4)',
  white_alpha60: 'rgba(255, 255, 255, 0.6)',

  whiteGradient:
    'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 40%, #fff 100%)',

  black: '#000',
  black100: '#141b27',
  black200: '#151b26',

  blackGradient100:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 53.04%, #000 100%)',

  blackShadow:
    '0 5px 8px 0 rgba(0, 0, 0, 0.20), 0 0 10px 0 rgba(0, 0, 0, 0.05)',

  gray: '#a4a4a4',
  gray100: '#eaecef',
  gray200: '#f3f4f5',
  gray300: '#7c7f85',
  gray400: '#d0d5dc',
  gray500: '#a5adba',
  gray600: '#d8d8d8',
  gray700: '#f6f7f8',
  gray800: '#f5f5f5',
  gray900: '#dee4ec',
  gray1000: '#bababa',
  gray1100: '#d3dadf',
  gray1200: '#93999e',
  gray1300: '#ebebeb',
  gray1400: '#e6ecf6',
  gray1500: '#5c5e62',
  gray1500_alpha40: 'rgba(92, 94, 98, 0.4)',
  gray1600: '#ebecf0',
  gray1700: '#a5adba',
  gray1800: '#c0c6d1',

  grayGradient100:
    'linear-gradient(180deg, #e8e8e8 0%, #f5f5f5 57.82%, #e1e1e1 93.32%)',

  grayShadow:
    '0 10px 15px 0 rgba(165, 173, 186, 0.10), 0 5px 10px 0 rgba(165, 173, 186, 0.10)',

  blue: '#0061ed',
  blue_alpha05: 'rgba(0, 97, 237, 0.05)',
  blue100: '#0638CC',
  blue200: '#e8f1ff',
  blue300: '#d4e3fa',
  blue400: '#d8e6fc',
  blue500: '#0556cc',
  blue600: '#505f79',
  blue700: '#172b4d',
  blue800: '#a9d3fd',
  blue900: '#9abfe3',
  blue1000: '#003da5',
  blue1100: '#0052dd',
  blue1200: '#39465c',
  blue1300_alpha70: 'rgba(0, 26, 63, 0.7)',

  blueGradient: 'linear-gradient(90deg, #e5dcfd 0%, #daf2ff 100%)',
  blueGradient100: 'linear-gradient(90deg, #e0e9ff 0%, #ffe0cc 100%)',

  yellow: '#ffd101',
  yellow100: '#ffd159',
  yellow200: '#f7c73e',
  yellow300: '#ffe781',

  yellowGradient: 'linear-gradient(90deg, #ffe681 -1.49%, #ffddc5 100%)',

  green: '#429834',
  green100: '#5f9717',
  green200: '#e6ffdb',
  green300: '#2ac81c',
  green400: '#3db775',

  greenGradient: 'linear-gradient(90deg, #f0ffad 0%, #75e881 100%)',

  red: '#d30000',
  red100: '#f84949',
  red200: '#ffdcdc',
  red300: '#ed0000',
  red400: '#b00505',
  red500: '#fff2f2',
  red600: '#f44336',
  red700: '#ee8f8f',
  red800: '#ef3124',
  red800_alpha50: 'rgba(239, 49, 36, 0.5)',

  pink: '#f7246c',

  pinkGradient: 'linear-gradient(90deg, #e5dbff 0%, #ffd5d3 100%)',
  pinkGradient100: 'linear-gradient(90deg, #ffc5c5 -1.49%, #ffe6b6 100%)',

  orange: '#ffa51d',
  orange100: '#cd5700',
  orange200: '#ff8933',
  orange300: '#ffeddf',
  orange400: '#ff722c',

  purple: '#1a0775',

  aqua: '#d8fcfc',
  aqua100: '#008686',

  purpleGradient: 'linear-gradient(180deg, #0b013c 0%, #1a0775 100%)',
  purpleGradient100: 'linear-gradient(180deg, #1b0875 0%, #fff 76.04%)',
  purpleGradient200: 'linear-gradient(90deg, #daccff 0%, #c2daff 100%)',
  purpleGradient300:
    'linear-gradient(91deg, #6c82ce 32.65%, rgba(137, 198, 212, 0) 69.79%)',
  purpleGradient400: 'linear-gradient(90deg, #7235d6 0%, #256de8 100%)',
  purpleGradient500:
    'linear-gradient(90deg, #7235d6 0%, #256de8 50%, #0052dd 100%)',
} as const;

const colorsB2b = {
  ...colorsMain,

  main: '#0066b3',
  main100: '#004e89',
} as const;

const colorsHisun = {
  ...colorsMain,

  main: '#670b22',
  main100: '#470818',
} as const;

const colorsJac = {
  ...colorsMain,

  main: '#005f7f',
  main100: '#02405b',
} as const;

const colorsSollers = {
  ...colorsMain,

  main: '#f47c30',
  main100: '#ffae62',
} as const;

const colorsJetour = {
  ...colorsMain,

  main: '#68a598',
  main100: '#628788',
} as const;

const colorsZeekr = {
  ...colorsMain,

  main: '#f76400',
  main100: '#db5a02',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'main':
      return colorsMain;
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'sollers':
      return colorsSollers;
    case 'jetour':
      return colorsJetour;
    case 'zeekr':
      return colorsZeekr;
    default:
      return colorsMain;
  }
})();

const robotoFont = Roboto({
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin', 'cyrillic'],
});

const grtskTeraFont = localFont({
  src: [
    {
      path: './fonts/GrtskTera/GrtskTera-Semibold.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
});

const sfProDisplayFont = localFont({
  src: [
    {
      path: './fonts/SFProDisplay/SFProDisplay-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-LightItalic.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-RegularItalic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/SFProDisplay/SFProDisplay-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
});

const ttCommonsFont = localFont({
  src: [
    {
      path: './fonts/TTCommons/TTCommons-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/TTCommons/TTCommons-LightItalic.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: './fonts/TTCommons/TTCommons-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/TTCommons/TTCommons-RegularItalic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: './fonts/TTCommons/TTCommons-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/TTCommons/TTCommons-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: './fonts/TTCommons/TTCommons-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/TTCommons/TTCommons-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
});

export const fonts = {
  Roboto: robotoFont.style.fontFamily,
  GrtskTera: grtskTeraFont.style.fontFamily,
  SFProDisplay: sfProDisplayFont.style.fontFamily,
  TTCommonsFont: ttCommonsFont.style.fontFamily,
};

export const getFont = (): string => {
  const theme = getTheme();

  switch (theme) {
    case 'zeekr':
      return fonts.SFProDisplay;
    case 'sollers':
      return fonts.TTCommonsFont;
    default:
      return fonts.Roboto;
  }
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1540,
};
