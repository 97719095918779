import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import Router from 'next/router';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { checkCode, userApiService } from '@/services/users/users-service';
import {
  getUserPersonalDataThunk,
  getUserProfilePreviewThunk,
} from '@/store/reducers/cabinet';
import {
  AuthData,
  LoginFormPayload,
  LoginFormResponse,
} from '@/services/users/typings';

interface State {
  status: 'AUTHORIZED' | 'UNAUTHORIZED';
  profile: {
    status: FetchStatus;
    data: Nullable<AuthData>;
  };
}

const initialState: State = {
  status: 'UNAUTHORIZED',
  profile: {
    status: 'IDLE',
    data: null,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorized: (state) => {
      state.status = 'AUTHORIZED';
    },
    setUnauthorized: (state) => {
      state.status = 'UNAUTHORIZED';
    },
    setInitialUserProfile: (state) => {
      state.status = 'UNAUTHORIZED';
      state.profile = initialState.profile;
    },
    setProfilePending: (state) => {
      state.profile.status = 'LOADING';
    },
    setProfileSucceeded: (state, action: PayloadAction<AuthData>) => {
      state.profile.status = 'SUCCESS';
      state.profile.data = action.payload;
    },
    setProfileFailed: (state) => {
      state.profile.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  setAuthorized,
  setUnauthorized,
  setInitialUserProfile,
  setProfilePending,
  setProfileSucceeded,
  setProfileFailed,
} = slice.actions;

/** Thunks **/

export function getProfileThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(setProfilePending());

    try {
      const token = userApiService.getAccessToken();
      const decodedProfile = jwtDecode<AuthData>(token ?? '');

      if (decodedProfile) {
        dispatch(setProfileSucceeded(decodedProfile));
      } else {
        dispatch(setProfileFailed());
        return;
      }
    } catch (error) {
      dispatch(setProfileFailed());
      return Promise.reject(error);
    }
  };
}

export function checkAuthorizationThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const token = userApiService.getAccessToken();

      if (!token) {
        dispatch(setUnauthorized());
        return;
      }

      await Promise.all([
        dispatch(getUserProfilePreviewThunk()),
        dispatch(getUserPersonalDataThunk()),
        dispatch(getProfileThunk()),
      ]);

      dispatch(setAuthorized());
    } catch (error) {
      dispatch(setUnauthorized());
      return Promise.reject(error);
    }
  };
}

export const signInThunk =
  (payload: LoginFormPayload): AppThunk<Promise<Nullable<LoginFormResponse>>> =>
  async () => {
    try {
      const response = await checkCode(payload);

      userApiService.setAccessToken(response.token);

      if (!response.isNew) {
        window.location.reload();
      }

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const signInWithSocialThunk =
  (token: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      userApiService.setAccessToken(token);
      window.location.reload();
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const signOutThunk = (): AppThunk => (dispatch) => {
  Router.push('/').then(() => {
    dispatch(setInitialUserProfile());
    userApiService.setAccessToken(null);
  });

  return Promise.resolve();
};

/** Selectors **/

export function getIsUserAuthorized(state: AppState): boolean {
  return state.auth.status === 'AUTHORIZED';
}
