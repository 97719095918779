import React from 'react';
import { useTheme } from 'styled-components';

import { ThemeColorsType, ThemeContextType } from './types';
import { ThemeContext } from './ThemeContext';

export const useThemeContext = (): ThemeContextType =>
  React.useContext(ThemeContext);

export const useStyledComponentsTheme = (): ThemeColorsType => {
  return useTheme() as ThemeColorsType;
};
