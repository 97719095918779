import { CarsType } from '@/typings/common';
import { ApplicationModalType } from '@/components/modals/DetailedCarModal/DetailedCarModal';

export const INITIAL_FORM_VALUES = {
  type: 'new' as CarsType,
  id: 0,
  dealer: 0,
  creditAmount: '',
  myPrice: '',
  myCarBrand: '',
  myCarModel: '',
  myCarYear: '',
  name: '',
  phone: '',
};

export function getInitialOptions(
  typeCar: CarsType,
  typeApplicationModal: ApplicationModalType
) {
  const baseFormOptions = [
    { value: 1, label: 'Хочу в кредит', checked: false },
    { value: 2, label: 'Хочу в Trade-in', checked: false },
  ];
  return typeCar === 'new'
    ? baseFormOptions
    : typeApplicationModal === 'CUSTOM_PRICE'
    ? [...baseFormOptions, { value: 3, label: 'Своя цена', checked: true }]
    : baseFormOptions;
}
