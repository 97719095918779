import React from 'react';

export const phoneRegexp =
  /^\+375 \((17|25|29|33|44)\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
export const emailRegexp =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum TypesOfValidate {
  PHONE = 'PHONE',
  NUMBER = 'NUMBER',
  NOT_NUMBER = 'NOT_NUMBER',
  TEXT = 'TEXT',
  TEXT_AND_NUMBER = 'TEXT_AND_NUMBER',
}

export const handleValidateField = (
  event: React.ChangeEvent<HTMLInputElement>,
  handleChange: (event: React.ChangeEvent<any>) => void,
  typeOfValidate: TypesOfValidate,
  maxLength?: number
) => {
  const regPhoneNumber = /^[0-9()+-]+$/;
  const regNumber = /^[0-9]+$/;
  const regNotNumber = /^[^0-9]+$/;
  const regText = /^[A-Za-zА-Яа-я]+$/;
  const regTextAndNumbers = /^[A-Za-zА-Яа-я0-9_]+$/;

  const { value } = event.target;

  switch (typeOfValidate) {
    case TypesOfValidate.PHONE:
      if (regPhoneNumber.test(value) || !value) {
        handleChange(event);
      }
      break;

    case TypesOfValidate.NUMBER:
      if (
        (maxLength && value.length <= maxLength && regNumber.test(value)) ||
        !value
      ) {
        handleChange(event);
      }
      break;

    case TypesOfValidate.NOT_NUMBER:
      if (regNotNumber.test(value) || !value) {
        handleChange(event);
      }
      break;

    case TypesOfValidate.TEXT:
      if (regText.test(value) || !value) {
        handleChange(event);
      }
      break;

    case TypesOfValidate.TEXT_AND_NUMBER:
      if (
        (maxLength &&
          value.length <= maxLength &&
          regTextAndNumbers.test(value)) ||
        !value
      ) {
        handleChange(event);
      }
      break;

    default:
      console.log('error');
  }
};
