import React, { useCallback, useState } from 'react';
import { ThemeProvider as ThemeProviderStyledComponents } from 'styled-components';
import { useRouter } from 'next/router';

import { useIsomorphicLayoutEffect } from '@tager/web-core';

import {
  getColorsByTheme,
  getInitialTheme,
  isThemeVisible,
} from '@/theme/helpers';

import { ThemeTypes } from './types';
import { ThemeContext } from './ThemeContext';
import { Theme } from './Theme';

export const ThemeProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const [theme, setNewTheme] = useState<ThemeTypes>('mainLight');
  const [isVisibleTheme, setVisibleTheme] = useState(false);

  const setTheme = useCallback((theme: ThemeTypes) => {
    setNewTheme(theme);
  }, []);

  useIsomorphicLayoutEffect(() => {
    setTheme(getInitialTheme());
    setVisibleTheme(isThemeVisible());
  }, [router, setTheme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProviderStyledComponents theme={getColorsByTheme(theme)}>
        {isVisibleTheme && <Theme />}
        {children}
      </ThemeProviderStyledComponents>
    </ThemeContext.Provider>
  );
};
