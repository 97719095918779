import { CreditType, OptionType } from '@/typings/common';

export const defaultPrepayment = 50;

export const tabs: OptionType<CreditType>[] = [
  { value: 'credit', label: 'Кредит' },
  { value: 'leasing', label: 'Лизинг' },
];

export const maxPrepayment = 99;
