import { useState } from 'react';

interface Config {
  stepsLabels: Array<string>;
  initialActiveStepIndex: number;
}

function initializeSteps({ initialActiveStepIndex, stepsLabels }: Config) {
  return stepsLabels.map((label, index) => ({
    label,
    isActive: index === initialActiveStepIndex,
    isCompleted: false,
  }));
}

function useSteps(config: Config) {
  const [state, setState] = useState(initializeSteps(config));
  const [currentIndex, setCurrentIndex] = useState(
    config.initialActiveStepIndex
  );

  function nextStep() {
    if (currentIndex >= state.length) return;
    const nextIndex = currentIndex + 1;
    const newState = state.map((step, index) => {
      if (index === nextIndex) {
        return { ...step, isActive: true, isCompleted: false };
      } else {
        return { ...step, isActive: false, isCompleted: true };
      }
    });

    setState(newState);
    setCurrentIndex((prev) => ++prev);
  }

  function prevStep() {
    if (currentIndex === 0) return;
    const prevIndex = currentIndex - 1;

    const newState = state.map((step, index) => {
      if (index === prevIndex) {
        return { ...step, isActive: true, isCompleted: false };
      } else {
        return { ...step, isActive: false, isCompleted: false };
      }
    });

    setState(newState);
    setCurrentIndex((prev) => --prev);
  }

  return { nextStep, prevStep, steps: state };
}

export default useSteps;
