import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';

import { convertRequestErrorToMap, Nullable } from '@tager/web-core';

import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import { BASE_APPLICATION_TITLE } from '@/components/modals/DetailedCarModal/components/Application/common.constans';
import {
  BaseComponentProps,
  ITestDriveFormUserValuesData,
} from '@/components/modals/DetailedCarModal/components/Application/common.types';
import { TestDriveData } from '@/typings/model';
import LocationStep from '@/components/modals/DetailedCarModal/components/Application/components/TestDrive/steps/LocationStep/LocationStep';
import {
  handleValidateContactForm,
  handleValidateTestDriveFormStep1,
  hasErrors,
} from '@/components/modals/DetailedCarModal/components/Application/common.helpers';
import ContactFormInner from '@/components/modals/DetailedCarModal/components/Application/components/common/ContactForm';
import { ResetErrors } from '@/components/modals/DetailedCarModal/components/Application/common.helpers';
import { stepLabelsMap } from '@/components/modals/DetailedCarModal/components/Application/components/TestDrive/TestDrive.constans';
import { CarProps } from '@/components/modals/DetailedCarModal/components/Application/common.types';
import { getTestDriveCarData } from '@/services/requests';
import Loader from '@/components/Loader';
import useSteps from '@/components/Steps/Steps.hooks';
import Steps from '@/components/Steps';
import { googleEvent } from '@/utils/events';
import {
  CarPreviewWrapper,
  StepsWrapper,
} from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import CarPreview from '@/components/CarPreview';
import useSettingItem from '@/hooks/useSettingItem';
import { submitDetailedCarTestDrive } from '@/services/leads/leads-service';
import { DetailedCarTestDriveParams } from '@/services/leads/typings';

import { pad } from './steps/LocationStep/utils';

function TestDrive({
  closeModal,
  onSuccess,
  type,
  typeState,
  car,
}: BaseComponentProps) {
  const [testDriveData, setTestDriveData] =
    useState<Nullable<TestDriveData>>(null);
  const [isShowTimePicker, setIsShowTimePicker] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [testDriveDate, setTestDriveDate] =
    useState<Nullable<Array<string>>>(null);
  const testDriveGoogleEvent = useSettingItem(
    typeState === 'new'
      ? 'DETAILED_CAR_NEW_TEST_DRIVE_GOOGLE_EVENT'
      : 'DETAILED_CAR_AMP_TEST_DRIVE_GOOGLE_EVENT'
  );

  function getInitialValuesTestDriveForm(
    car: CarProps
  ): ITestDriveFormUserValuesData {
    return {
      date: null,
      carId: `${car.id}`,
      dealerId: null,
      phone: '',
      comment: '',
      name: '',
      email: '',
      outsideTestDrive: false,
      time: '',
    };
  }

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const responseData = await getTestDriveCarData(car.id, typeState);
        setTestDriveData(responseData);
      } catch (error) {
        toast.error('Тест-драйв не доступен - дилер не создан');
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [car.id, typeState]);

  const { steps, prevStep, nextStep } = useSteps({
    stepsLabels: stepLabelsMap[typeState],
    initialActiveStepIndex: 0,
  });

  const firstStep = steps[0];
  const secondStep = steps[1];

  const handleIsShownTimePicker = (isShown: boolean): void => {
    setIsShowTimePicker(isShown);
  };

  async function onSubmit(
    values: ITestDriveFormUserValuesData,
    formikHelper: FormikHelpers<ITestDriveFormUserValuesData>
  ) {
    formikHelper.setSubmitting(true);

    if (!values.date) {
      return;
    }

    const formatDateTime = `${values.date.getUTCFullYear()}-${pad(
      values.date.getUTCMonth() + 1
    )}-${pad(values.date.getUTCDate() + 1)} ${values.time}`;

    const sendingData: DetailedCarTestDriveParams = {
      id: car.id,
      model: car.name,
      type: typeState,
      location: values.dealerId,
      datetime: formatDateTime,
      name: values.name,
      phone: values.phone,
      email: values.email,
      message: values.comment,
    };
    try {
      await submitDetailedCarTestDrive(sendingData).then(() => {
        if (testDriveGoogleEvent) {
          googleEvent(testDriveGoogleEvent, car.googleEventParams);
        }
      });
      setLoading(false);

      onSuccess();
    } catch (error: any) {
      const errorMap = convertRequestErrorToMap(
        error
      ) as FormikErrors<ITestDriveFormUserValuesData>;
      formikHelper.setErrors(errorMap);
      console.error(error);
    }

    formikHelper.setSubmitting(false);
  }

  return !isLoading && testDriveData ? (
    <S.Component onClick={() => handleIsShownTimePicker(false)}>
      <S.StepTitle>{BASE_APPLICATION_TITLE[typeState][type]}</S.StepTitle>

      <CarPreviewWrapper>
        <CarPreview
          image={car.image}
          name={car.name}
          price={car.price}
          currency="BYN"
          body={car.bodyType}
          carType={typeState}
        />
      </CarPreviewWrapper>

      <StepsWrapper>
        <Steps
          secondStep={secondStep}
          firstStep={firstStep}
          prevStep={prevStep}
        />
      </StepsWrapper>

      <Formik<ITestDriveFormUserValuesData>
        onSubmit={onSubmit}
        validate={
          firstStep.isActive
            ? handleValidateTestDriveFormStep1
            : handleValidateContactForm
        }
        initialValues={getInitialValuesTestDriveForm(car)}
        validateOnChange
      >
        {({
          handleSubmit,
          values,
          errors,
          handleChange,
          setErrors,
          setFieldValue,
          touched,
          handleBlur,
          isSubmitting,
        }) => {
          return (
            <>
              <S.Form onSubmit={handleSubmit}>
                {firstStep.isActive ? (
                  <LocationStep
                    outdoorAvailable={testDriveData.outdoorAvailable}
                    isShowTimePicker={isShowTimePicker}
                    setIsShownTimePicker={handleIsShownTimePicker}
                    typeState={typeState}
                    onChange={handleChange}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    locations={testDriveData.locations}
                    closeModal={closeModal}
                    touched={touched}
                    nextStep={nextStep}
                    hasErrors={hasErrors(
                      handleValidateTestDriveFormStep1(values)
                    )}
                    testDriveDate={testDriveDate}
                    setTestDriveDate={setTestDriveDate}
                    car={car}
                  />
                ) : (
                  <ContactFormInner
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                    values={values}
                    touched={touched}
                    hasErrors={hasErrors(handleValidateContactForm(values))}
                    onPrevStep={prevStep}
                    isSubmitting={isSubmitting}
                  />
                )}
              </S.Form>

              <ResetErrors
                isFirstStep={firstStep.isActive}
                setErrors={setErrors}
              />
            </>
          );
        }}
      </Formik>
    </S.Component>
  ) : (
    <Loader />
  );
}

export default TestDrive;
