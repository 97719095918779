import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';
import { getSettingValueByKey } from '@tager/web-modules';

import { AppState, AppThunk } from '@/store/store';
import { CarViewTypes } from '@/components/CarViewToggle/types';
import { CatalogStockBrandAndModelsResponse } from '@/services/catalog/typings';
import { getCatalogStockBrandsAndModels } from '@/services/catalog/catalog-service';
import {
  CarsStockFiltersParams,
  CarsStockFiltersParamsResponse,
  CarsStockListParams,
  CarsStockListResponse,
  DetailedCarData,
  SelectedFiltersPayload,
} from '@/services/stock/typings';
import {
  fetchDetailedCarData,
  getCarsStockFiltersParams,
  getCarsStockList,
} from '@/services/stock/stock-service';
import {
  CarsStockType,
  OptionType,
  RangeOptionType,
  RangeType,
} from '@/typings/common';
import {
  StockFilterMultiSelectPartsOptionType,
  StockFilterMultiSelectPartsSelectedOptionType,
} from '@/components/StockFilterMultiSelectParts/types';
import {
  createBrandsAndModelsPayload,
  createFilterPayload,
  getDefaultCatalogFilter,
} from '@/modules/CarsStock/components/common.helpers';
import { ColorOptionType } from '@/components/StockColorPicker/types';
import {
  ComparisonCarsType,
  GearboxNames,
} from '@/modules/CarsStock/components/common.types';
import { pageLimit } from '@/modules/CarsStock/components/common.constants';
import { CarsStockSort } from '@/services/stock/enums';

export interface SelectedCarsType {
  selectedBrands: OptionType[];
  selectedModels: StockFilterMultiSelectPartsSelectedOptionType[];
  selectedComplectations: StockFilterMultiSelectPartsSelectedOptionType[];
}

export interface SelectedFiltersType {
  selectedCars: SelectedCarsType;
  selectedParams: {
    selectedBodies: OptionType[];
    selectedPriceRange: RangeType;
    selectedGearbox: StockFilterMultiSelectPartsSelectedOptionType[];
    selectedWheel: OptionType[];
    selectedColors: ColorOptionType[];
    selectedEngineType: OptionType[];
    selectedEngineCapacity: RangeOptionType;
    selectedYear: RangeType;
    selectedMileage: RangeType;
    selectedVat: OptionType;
    selectedLocation: OptionType[];
    selectedPowerReserve: RangeType;
    selectedBattery: OptionType[];
    selectedBatteryCapacity: RangeType;
    selectedEnginePower: RangeType;
    selectedClearance: RangeType;
    selectedSeats: RangeType;
    selectedDoors: RangeType;
    selectedElectricEngines: OptionType[];
    selectedGrades: OptionType[];
    selectedSpecials: {
      selectedSuperPrice: OptionType;
      selectedGuarantee: OptionType;
      selectedApproved: OptionType;
      selectedPartnerCar: OptionType;
      selectedAvailable: OptionType;
      selectedOnStock: OptionType;
    };
  };
}

interface State {
  brandsAndModels: {
    status: FetchStatus;
    data: CatalogStockBrandAndModelsResponse[];
  };

  filterParams: {
    status: FetchStatus;
    data: Nullable<CarsStockFiltersParamsResponse>;
  };

  carList: {
    status: FetchStatus;
    data: Nullable<CarsStockListResponse>;
  };

  selectedFilters: SelectedFiltersType;

  selectedSort: OptionType;

  selectedComparisonCars: ComparisonCarsType[];

  page: {
    currentPage: number;
    offset: number;
    limit: number;
  };

  carListView: CarViewTypes;

  isShowMoreParams: boolean;

  detailedCarList: {
    status: FetchStatus;
    data: DetailedCarData[];
  };
}

const initialState: State = {
  brandsAndModels: {
    status: 'IDLE',
    data: [],
  },

  filterParams: {
    status: 'IDLE',
    data: null,
  },

  carList: {
    status: 'IDLE',
    data: null,
  },

  selectedFilters: {
    selectedCars: {
      selectedBrands: [],
      selectedModels: [],
      selectedComplectations: [],
    },
    selectedParams: {
      selectedBodies: [],
      selectedPriceRange: { min: '', max: '' },
      selectedGearbox: [],
      selectedWheel: [],
      selectedColors: [],
      selectedEngineType: [],
      selectedEngineCapacity: {
        min: { label: '', value: '' },
        max: { label: '', value: '' },
      },
      selectedYear: { min: '', max: '' },
      selectedMileage: { min: '', max: '' },
      selectedVat: { label: '', value: '' },
      selectedLocation: [],
      selectedPowerReserve: { min: '', max: '' },
      selectedBattery: [],
      selectedBatteryCapacity: { min: '', max: '' },
      selectedEnginePower: { min: '', max: '' },
      selectedClearance: { min: '', max: '' },
      selectedSeats: { min: '', max: '' },
      selectedDoors: { min: '', max: '' },
      selectedElectricEngines: [],
      selectedGrades: [],
      selectedSpecials: {
        selectedSuperPrice: { label: '', value: '' },
        selectedGuarantee: { label: '', value: '' },
        selectedApproved: { label: '', value: '' },
        selectedPartnerCar: { label: '', value: '' },
        selectedAvailable: { label: '', value: '' },
        selectedOnStock: { label: '', value: '' },
      },
    },
  },

  selectedSort: { label: '', value: '' },

  selectedComparisonCars: [],

  page: {
    currentPage: 1,
    offset: 0,
    limit: pageLimit,
  },

  carListView: 'portrait',

  isShowMoreParams: false,

  detailedCarList: {
    status: 'IDLE',
    data: [],
  },
};

const slice = createSlice({
  name: 'cars-stock',
  initialState,
  reducers: {
    /** Brands and models **/
    setBrandsAndModelsLoading(state) {
      state.brandsAndModels.status = 'LOADING';
    },
    setBrandsAndModelsSuccess(
      state,
      action: PayloadAction<CatalogStockBrandAndModelsResponse[]>
    ) {
      state.brandsAndModels.data = action.payload;
      state.brandsAndModels.status = 'SUCCESS';
    },
    setBrandsAndModelsFailure(state) {
      state.brandsAndModels.status = 'FAILURE';
    },

    /** Filter params **/
    setFilterParamsLoading(state) {
      state.filterParams.status = 'LOADING';
    },
    setFilterParamsSuccess(
      state,
      action: PayloadAction<CarsStockFiltersParamsResponse>
    ) {
      state.filterParams.data = action.payload;
      state.filterParams.status = 'SUCCESS';
    },
    setFilterParamsFailure(state) {
      state.filterParams.status = 'FAILURE';
    },

    /** Car list **/
    setCarListLoading(state) {
      state.carList.status = 'LOADING';
    },
    setCarListSuccess(state, action: PayloadAction<CarsStockListResponse>) {
      state.carList.data = action.payload;
      state.carList.status = 'SUCCESS';
    },
    setCarListFailure(state) {
      state.carList.status = 'FAILURE';
    },

    /** Set filters from URL **/

    setCarBrandsArray: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedCars.selectedBrands = action.payload;
    },
    setCarModelsArray: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsSelectedOptionType[]>
    ) => {
      state.selectedFilters.selectedCars.selectedModels = action.payload;
    },
    setCarComplectationsArray: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsSelectedOptionType[]>
    ) => {
      state.selectedFilters.selectedCars.selectedComplectations =
        action.payload;
    },

    /** Selected filters **/
    setCarBrand: (state, action: PayloadAction<OptionType>) => {
      const selectedBrands = state.selectedFilters.selectedCars.selectedBrands;
      const selectedModels = state.selectedFilters.selectedCars.selectedModels;
      const selectComplectations =
        state.selectedFilters.selectedCars.selectedComplectations;

      const newBrand = action.payload;

      if (
        !selectedBrands?.find(
          (searchOption) => searchOption.value === newBrand.value
        )
      ) {
        if (selectedBrands) {
          state.selectedFilters.selectedCars.selectedBrands = [
            ...selectedBrands,
            newBrand,
          ];
        } else {
          state.selectedFilters.selectedCars.selectedBrands = [newBrand];
        }
      } else {
        state.selectedFilters.selectedCars.selectedBrands =
          selectedBrands.filter(
            (filteredOption) => filteredOption.value !== newBrand.value
          );

        state.selectedFilters.selectedCars.selectedModels =
          selectedModels.filter((model) => model.title !== newBrand.label);

        state.selectedFilters.selectedCars.selectedComplectations =
          selectComplectations.filter((complectation) =>
            state.selectedFilters.selectedCars.selectedModels.some(
              (model) => model.label === complectation.title
            )
          );
      }
    },
    deleteCarBrands: (state) => {
      state.selectedFilters.selectedCars.selectedBrands = [];
      state.selectedFilters.selectedCars.selectedModels = [];
      state.selectedFilters.selectedCars.selectedComplectations = [];
    },
    setCarModel: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsSelectedOptionType>
    ) => {
      const selectedModels = state.selectedFilters.selectedCars.selectedModels;
      const selectedComplectations =
        state.selectedFilters.selectedCars.selectedComplectations;
      const newModel = action.payload;
      if (
        !selectedModels?.find(
          (searchOption) =>
            searchOption.title === newModel.title &&
            searchOption.value === newModel.value
        )
      ) {
        if (selectedModels) {
          state.selectedFilters.selectedCars.selectedModels = [
            ...selectedModels,
            newModel,
          ];
        } else {
          state.selectedFilters.selectedCars.selectedModels = [newModel];
        }
      } else {
        state.selectedFilters.selectedCars.selectedModels =
          selectedModels.filter(
            (filteredOption) =>
              !(
                filteredOption.title === newModel.title &&
                filteredOption.value === newModel.value
              )
          );

        state.selectedFilters.selectedCars.selectedComplectations =
          selectedComplectations.filter(
            (complectation) => complectation.title !== newModel.label
          );
      }
    },
    setCarModelsAll: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsOptionType>
    ) => {
      const selectedModels = state.selectedFilters.selectedCars.selectedModels;
      const selectedComplectations =
        state.selectedFilters.selectedCars.selectedComplectations;
      const allModels = action.payload;

      const modelsCount = state.brandsAndModels.data
        .filter((item) => item.name === allModels.title)
        .map((item) => item.models)
        .flat(1).length;

      if (
        selectedModels?.filter(
          (searchOption) => searchOption.title === allModels.title
        ).length < modelsCount
      ) {
        state.selectedFilters.selectedCars.selectedModels = [
          ...selectedModels.filter((model) => model.title !== allModels.title),
          ...allModels.options.map((model) => ({
            label: model.label,
            value: model.value,
            title: allModels.title,
          })),
        ];
      } else {
        const newSelectedModels = selectedModels.filter(
          (model) => model.title !== allModels.title
        );
        state.selectedFilters.selectedCars.selectedModels = [
          ...newSelectedModels,
        ];
        state.selectedFilters.selectedCars.selectedComplectations = [
          ...selectedComplectations.filter(
            (complectation) =>
              newSelectedModels.filter(
                (item) => item.label === complectation.title
              ).length
          ),
        ];
      }
    },
    deleteCarModels: (state) => {
      state.selectedFilters.selectedCars.selectedModels = [];
      state.selectedFilters.selectedCars.selectedComplectations = [];
    },

    setCarComplectation: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsSelectedOptionType>
    ) => {
      const selectedComplectations =
        state.selectedFilters.selectedCars.selectedComplectations;
      const newComplectation = action.payload;

      if (
        !selectedComplectations?.find(
          (searchOption) =>
            searchOption.title === newComplectation.title &&
            searchOption.value === newComplectation.value
        )
      ) {
        if (selectedComplectations) {
          state.selectedFilters.selectedCars.selectedComplectations = [
            ...selectedComplectations,
            newComplectation,
          ];
        } else {
          state.selectedFilters.selectedCars.selectedComplectations = [
            newComplectation,
          ];
        }
      } else {
        state.selectedFilters.selectedCars.selectedComplectations =
          selectedComplectations.filter(
            (filteredOption) =>
              !(
                filteredOption.title === newComplectation.title &&
                filteredOption.value === newComplectation.value
              )
          );
      }
    },
    setCarComplectationsAll: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsOptionType>
    ) => {
      const selectedComplectations =
        state.selectedFilters.selectedCars.selectedComplectations;
      const allComplectations = action.payload;

      const complectationsCount = state.brandsAndModels.data
        .filter(
          (brand) =>
            brand.models.filter(
              (model) => model.name === allComplectations.title
            ).length
        )
        .map((item) => item.models)
        .flat(1)
        .filter((item) => item.name === allComplectations.title)
        .map((item) => item.complectations)
        .flat(1).length;

      if (
        selectedComplectations?.filter(
          (searchOption) => searchOption.title === allComplectations.title
        ).length < complectationsCount
      ) {
        state.selectedFilters.selectedCars.selectedComplectations = [
          ...selectedComplectations.filter(
            (item) => item.title !== allComplectations.title
          ),
          ...allComplectations.options.map((model) => ({
            label: model.label,
            value: model.value,
            title: allComplectations.title,
          })),
        ];
      } else {
        state.selectedFilters.selectedCars.selectedComplectations = [
          ...selectedComplectations.filter(
            (item) => item.title !== allComplectations.title
          ),
        ];
      }
    },
    deleteCarComplectations: (state) => {
      state.selectedFilters.selectedCars.selectedComplectations = [];
    },

    setCarBodies: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedBodies = action.payload;
    },
    setCarPrice: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedPriceRange = action.payload;
    },
    setCarGearbox: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsSelectedOptionType>
    ) => {
      const selectedGearbox =
        state.selectedFilters.selectedParams.selectedGearbox;
      const newGearbox = action.payload;

      if (
        !selectedGearbox?.find(
          (searchOption) =>
            searchOption.title === newGearbox.title &&
            searchOption.value === newGearbox.value
        )
      ) {
        if (selectedGearbox) {
          state.selectedFilters.selectedParams.selectedGearbox = [
            ...selectedGearbox,
            newGearbox,
          ];
        } else {
          state.selectedFilters.selectedParams.selectedGearbox = [newGearbox];
        }
      } else {
        state.selectedFilters.selectedParams.selectedGearbox =
          selectedGearbox.filter(
            (filteredOption) =>
              !(
                filteredOption.title === newGearbox.title &&
                filteredOption.value === newGearbox.value
              )
          );
      }
    },
    setCarGearboxAll: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsOptionType>
    ) => {
      const selectedGearbox =
        state.selectedFilters.selectedParams.selectedGearbox;
      const allGearbox = action.payload;

      if (allGearbox.title === GearboxNames.AUTOMAT) {
        const automatGearboxCount = state.filterParams.data?.gearboxes.filter(
          (gear) => gear.label !== GearboxNames.MANUAL
        ).length;

        if (
          automatGearboxCount &&
          selectedGearbox?.filter(
            (searchOption) => searchOption.title === allGearbox.title
          ).length < automatGearboxCount
        ) {
          state.selectedFilters.selectedParams.selectedGearbox = [
            ...selectedGearbox.filter(
              (item) => item.title !== allGearbox.title
            ),
            ...allGearbox.options.map((gearbox) => ({
              label: gearbox.label,
              value: gearbox.value,
              title: GearboxNames.AUTOMAT,
            })),
          ];
        } else {
          state.selectedFilters.selectedParams.selectedGearbox = [
            ...selectedGearbox.filter(
              (item) => item.title !== allGearbox.title
            ),
          ];
        }
      } else {
        if (
          !selectedGearbox.some((gearbox) => gearbox.title === allGearbox.title)
        ) {
          const manualGearbox = state.filterParams.data?.gearboxes.filter(
            (gear) => gear.label === GearboxNames.MANUAL
          );

          if (manualGearbox) {
            state.selectedFilters.selectedParams.selectedGearbox = [
              ...selectedGearbox.filter(
                (item) => item.title !== allGearbox.title
              ),
              ...manualGearbox.map((gearbox) => ({
                label: GearboxNames.MANUAL,
                value: gearbox.id,
                title: GearboxNames.MANUAL,
              })),
            ];
          }
        } else {
          state.selectedFilters.selectedParams.selectedGearbox = [
            ...selectedGearbox.filter(
              (item) => item.title !== allGearbox.title
            ),
          ];
        }
      }
    },
    setCarGearboxArray: (
      state,
      action: PayloadAction<StockFilterMultiSelectPartsSelectedOptionType[]>
    ) => {
      state.selectedFilters.selectedParams.selectedGearbox = action.payload;
    },
    deleteCarGearbox: (state) => {
      state.selectedFilters.selectedParams.selectedGearbox = [];
    },
    setCarWheel: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedWheel = action.payload;
    },
    setCarColors: (state, action: PayloadAction<ColorOptionType[]>) => {
      state.selectedFilters.selectedParams.selectedColors = action.payload;
    },
    setCarEngineTypes: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedEngineType = action.payload;
    },
    setCarEngineCapacity: (state, action: PayloadAction<RangeOptionType>) => {
      state.selectedFilters.selectedParams.selectedEngineCapacity =
        action.payload;
    },
    setCarYear: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedYear = action.payload;
    },
    setCarMileage: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedMileage = action.payload;
    },
    setCarVat: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedVat = action.payload;
    },
    setCarLocations: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedLocation = action.payload;
    },
    setCarPowerReserve: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedPowerReserve =
        action.payload;
    },
    setCarBattery: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedBattery = action.payload;
    },
    setCarBatteryCapacity: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedBatteryCapacity =
        action.payload;
    },
    setCarEnginePower: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedEnginePower = action.payload;
    },
    setCarClearance: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedClearance = action.payload;
    },
    setCarSeats: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedSeats = action.payload;
    },
    setCarDoors: (state, action: PayloadAction<RangeType>) => {
      state.selectedFilters.selectedParams.selectedDoors = action.payload;
    },
    setCarElectricEngines: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedElectricEngines =
        action.payload;
    },
    setCarGrades: (state, action: PayloadAction<OptionType[]>) => {
      state.selectedFilters.selectedParams.selectedGrades = action.payload;
    },
    setCarSuperPrice: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice =
        action.payload;
    },
    setCarGuarantee: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedSpecials.selectedGuarantee =
        action.payload;
    },
    setCarApproved: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedSpecials.selectedApproved =
        action.payload;
    },
    setCarPartnerCar: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar =
        action.payload;
    },
    setCarAvailable: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedSpecials.selectedAvailable =
        action.payload;
    },
    setCarOnStock: (state, action: PayloadAction<OptionType>) => {
      state.selectedFilters.selectedParams.selectedSpecials.selectedOnStock =
        action.payload;
    },

    resetCarsStockState: (state) => ({
      ...initialState,
      selectedComparisonCars: state.selectedComparisonCars,
    }),

    setCurrentSort: (state, action: PayloadAction<OptionType>) => {
      state.selectedSort = action.payload;
    },

    setComparisonCar: (state, action: PayloadAction<ComparisonCarsType>) => {
      const carId = action.payload.id;

      if (
        state.selectedComparisonCars.find(
          (comparisonCar) => comparisonCar.id === carId
        )
      ) {
        state.selectedComparisonCars = state.selectedComparisonCars.filter(
          (comparisonCar) => comparisonCar.id !== carId
        );
      } else {
        state.selectedComparisonCars.push(action.payload);
      }
    },
    resetComparisonCars: (state) => {
      state.selectedComparisonCars = [];
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page.currentPage = action.payload;
      state.page.offset = state.page.currentPage * state.page.limit - pageLimit;
    },

    setCarListView: (state, action: PayloadAction<CarViewTypes>) => {
      state.carListView = action.payload;
    },

    setShowMoreParams: (state, action: PayloadAction<boolean>) => {
      state.isShowMoreParams = action.payload;
    },

    setDetailedCarListLoading: (state) => {
      state.detailedCarList.status = 'LOADING';
    },
    setDetailedCarListSuccess: (
      state,
      action: PayloadAction<DetailedCarData[]>
    ) => {
      state.detailedCarList.data = action.payload;
      state.detailedCarList.status = 'SUCCESS';
    },
    setDetailedCarListFailure: (state) => {
      state.detailedCarList.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  setBrandsAndModelsLoading,
  setBrandsAndModelsSuccess,
  setBrandsAndModelsFailure,

  setFilterParamsLoading,
  setFilterParamsSuccess,
  setFilterParamsFailure,

  setCarListLoading,
  setCarListSuccess,
  setCarListFailure,

  setCarBrandsArray,
  setCarModelsArray,
  setCarComplectationsArray,

  setCarBrand,
  deleteCarBrands,
  setCarModel,
  setCarModelsAll,
  deleteCarModels,
  setCarComplectation,
  setCarComplectationsAll,
  deleteCarComplectations,
  setCarBodies,
  setCarApproved,
  setCarPartnerCar,
  setCarColors,
  setCarEngineCapacity,
  setCarEngineTypes,
  setCarGearbox,
  setCarGearboxAll,
  setCarGearboxArray,
  deleteCarGearbox,
  setCarWheel,
  setCarGuarantee,
  setCarLocations,
  setCarMileage,
  setCarPrice,
  setCarSuperPrice,
  setCarVat,
  setCarYear,
  setCarPowerReserve,
  setCarBattery,
  setCarBatteryCapacity,
  setCarAvailable,
  setCarOnStock,
  setCarEnginePower,
  setCarClearance,
  setCarSeats,
  setCarDoors,
  setCarElectricEngines,
  setCarGrades,

  resetCarsStockState,

  setCurrentSort,

  setComparisonCar,
  resetComparisonCars,

  setPage,

  setCarListView,

  setShowMoreParams,

  setDetailedCarListLoading,
  setDetailedCarListSuccess,
  setDetailedCarListFailure,
} = slice.actions;

/** Thunks **/

export const getStockBrandsAndModelsThunk =
  (
    carsType: CarsStockType
  ): AppThunk<Promise<CatalogStockBrandAndModelsResponse[]>> =>
  async (dispatch): Promise<CatalogStockBrandAndModelsResponse[]> => {
    dispatch(setBrandsAndModelsLoading());
    try {
      const brandAndModels = await getCatalogStockBrandsAndModels(carsType);

      dispatch(setBrandsAndModelsSuccess(brandAndModels));
      return brandAndModels;
    } catch (error) {
      dispatch(setBrandsAndModelsFailure());
      return Promise.reject(error);
    }
  };

export const getStockFilterParamsThunk =
  (
    carsType: CarsStockType,
    params?: CarsStockFiltersParams
  ): AppThunk<Promise<CarsStockFiltersParamsResponse>> =>
  async (dispatch, getState): Promise<CarsStockFiltersParamsResponse> => {
    dispatch(setFilterParamsLoading());
    try {
      const state = getState();

      const activeBrands =
        getSettingValueByKey(
          state.tager.settings.settingItemList.data,
          'STOCK_NEW_BRANDS'
        ) ?? [];
      const activeModels =
        getSettingValueByKey(
          state.tager.settings.settingItemList.data,
          'STOCK_NEW_MODELS'
        ) ?? [];

      const defaultParams = createBrandsAndModelsPayload(
        state.carsStock.selectedFilters.selectedCars,
        carsType === 'new' ? activeBrands : undefined,
        carsType === 'new' ? activeModels : undefined
      );

      const filterParams = await getCarsStockFiltersParams(
        carsType,
        params ?? defaultParams
      );

      dispatch(setFilterParamsSuccess(filterParams));
      return filterParams;
    } catch (error) {
      dispatch(setFilterParamsFailure());
      return Promise.reject(error);
    }
  };

export const getStockCarListThunk =
  (
    carsType: CarsStockType,
    params?: CarsStockListParams,
    filters?: SelectedFiltersPayload,
    signal?: AbortSignal
  ): AppThunk =>
  async (dispatch, getState): Promise<CarsStockListResponse> => {
    dispatch(setCarListLoading());
    try {
      const state = getState();

      const [page, sort] = [state.carsStock.page, state.carsStock.selectedSort];

      const defaultParams = {
        offset: page.offset,
        limit: page.limit,
        sort: sort.value as CarsStockSort,
      };

      const activeBrands =
        getSettingValueByKey(
          state.tager.settings.settingItemList.data,
          'STOCK_NEW_BRANDS'
        ) ?? [];
      const activeModels =
        getSettingValueByKey(
          state.tager.settings.settingItemList.data,
          'STOCK_NEW_MODELS'
        ) ?? [];
      const activeDealers =
        getSettingValueByKey(
          state.tager.settings.settingItemList.data,
          'STOCK_NEW_DEALERS'
        )?.map((dealer) => Number(dealer)) ?? [];

      const filterPayload = createFilterPayload(
        state.carsStock.selectedFilters,
        state.carsStock.brandsAndModels.data
      );

      const defaultFilters = {
        filter: {
          ...filterPayload.filter,
          catalog: getDefaultCatalogFilter(
            carsType,
            activeBrands,
            activeModels,
            state.carsStock.selectedFilters.selectedCars,
            state.carsStock.brandsAndModels.data,
            filterPayload.filter.catalog ?? []
          ),
          dealers: carsType === 'new' ? activeDealers : undefined,
        },
      };

      const carList = await getCarsStockList(
        carsType,
        params ?? defaultParams,
        filters ?? defaultFilters,
        signal
      );

      dispatch(setCarListSuccess(carList));
      return carList;
    } catch (error) {
      dispatch(setCarListFailure());
      return Promise.reject(error);
    }
  };

export const getDetailedCarListThunk =
  (cars: ComparisonCarsType[]): AppThunk<Promise<DetailedCarData[]>> =>
  async (dispatch) => {
    dispatch(setDetailedCarListLoading());

    try {
      const detailedCarsData = await Promise.all(
        cars.map((car) => fetchDetailedCarData(car.carType, String(car.id)))
      );

      dispatch(setDetailedCarListSuccess(detailedCarsData));

      return detailedCarsData;
    } catch (error) {
      dispatch(setDetailedCarListFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const getStockBrandsAndModels = (
  state: AppState
): CatalogStockBrandAndModelsResponse[] => {
  return state.carsStock.brandsAndModels.data;
};

export const getStockFilterParams = (
  state: AppState
): Nullable<CarsStockFiltersParamsResponse> => {
  return state.carsStock.filterParams.data;
};

export const getStockFilterParamsStatus = (state: AppState): FetchStatus => {
  return state.carsStock.filterParams.status;
};

export const getStockCarList = (
  state: AppState
): Nullable<CarsStockListResponse> => {
  return state.carsStock.carList.data;
};

export const getStockCarListStatus = (state: AppState): FetchStatus => {
  return state.carsStock.carList.status;
};

export const getSelectedCars = (state: AppState): SelectedCarsType => {
  return state.carsStock.selectedFilters.selectedCars;
};

export const getSelectedFilters = (state: AppState): SelectedFiltersType => {
  return state.carsStock.selectedFilters;
};

export const getComparisonCars = (state: AppState): ComparisonCarsType[] => {
  return state.carsStock.selectedComparisonCars;
};

export const getCurrentSort = (state: AppState): OptionType => {
  return state.carsStock.selectedSort;
};

export const getCarListView = (state: AppState): CarViewTypes => {
  return state.carsStock.carListView;
};

export const getPage = (
  state: AppState
): { currentPage: number; offset: number; limit: number } => {
  return state.carsStock.page;
};

export const getShowMoreParams = (state: AppState): boolean => {
  return state.carsStock.isShowMoreParams;
};

export const getDetailedCarListData = (state: AppState): DetailedCarData[] => {
  return state.carsStock.detailedCarList.data;
};
