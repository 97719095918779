import { FormikErrors } from 'formik';

import { useUpdateEffect, validators } from '@tager/web-core';

import {
  DetailedCarOrderValues,
  DetailedCarTestDriveOfflineParams,
} from '@/services/leads/typings';
import { emailRegexp } from '@/utils/textInput';

import {
  ExtraOptionsType,
  ITestDriveFormUserValuesData,
  TestDriveFormValuesType,
} from './common.types';

const requiredMessage = 'Заполните поле';

export function handleValidateOrderForm(
  values: DetailedCarOrderValues,
  carPrice: number
): FormikErrors<DetailedCarOrderValues> {
  const errors: FormikErrors<DetailedCarOrderValues> = {};
  const creditAmount = Number(
    values.creditAmount?.toString().replace(' ', '').replace(' ', '')
  );

  if (values.myCarBrand && !values.myCarModel?.trim()) {
    errors.myCarModel = requiredMessage;
  }

  if (creditAmount && creditAmount <= 0) {
    errors.creditAmount = requiredMessage;
  }

  if (creditAmount && creditAmount > carPrice) {
    errors.creditAmount = 'Сумма кредита превышает стоимость авто';
  }

  if (values.myCarModel && !values.myCarBrand) {
    errors.myCarBrand = requiredMessage;
  }

  if (values.myCarYear && !values.myCarModel) {
    errors.myCarYear = requiredMessage;
  }

  if (values.myCarYear && !values.myCarBrand) {
    errors.myCarBrand = requiredMessage;
  }

  if (
    (values.myCarYear && Number(values.myCarYear) > new Date().getFullYear()) ||
    (values.myCarYear && Number(values.myCarYear) < 1961)
  ) {
    errors.myCarYear = requiredMessage;
  }

  if (validators.required(values.name)) {
    errors.name = requiredMessage;
  }

  if (validators.required(values.phone)) {
    errors.phone = requiredMessage;
  }

  if (!values.dealer) {
    errors.dealer = 'Выберите автоцентр';
  }

  return errors;
}

export function handleValidateTestDriveOfflineForm(
  values: DetailedCarTestDriveOfflineParams
): FormikErrors<DetailedCarTestDriveOfflineParams> {
  const errors: FormikErrors<DetailedCarTestDriveOfflineParams> = {};

  if (validators.required(values.name)) {
    errors.name = requiredMessage;
  }

  if (validators.required(values.phone)) {
    errors.phone = requiredMessage;
  }

  return errors;
}

export function handleValidateContactForm(
  values: ITestDriveFormUserValuesData
): FormikErrors<TestDriveFormValuesType> {
  const errors: FormikErrors<TestDriveFormValuesType> = {};

  if (validators.required(values.name)) {
    errors.name = requiredMessage;
  }

  if (validators.required(values.phone)) {
    errors.phone = requiredMessage;
  }

  if (!emailRegexp.test(values.email)) {
    errors.email = requiredMessage;
  }

  return errors;
}

export function handleValidateTestDriveFormStep1(
  values: ITestDriveFormUserValuesData
): FormikErrors<TestDriveFormValuesType> {
  const errors: FormikErrors<TestDriveFormValuesType> = {};

  if (!values.date) {
    errors.date = requiredMessage;
  }

  if (!values.outsideTestDrive) {
    if (validators.required(`${values.dealerId}`)) {
      errors.location = requiredMessage;
    }
  }

  if (validators.required(values.time)) {
    errors.time = requiredMessage;
  }

  return errors;
}

export function convertFormValues(values: DetailedCarOrderValues) {
  const keys = Object.keys(values) as Array<keyof typeof values>;

  keys.forEach((key) => {
    if (!values[key]?.toString().trim()) {
      delete values[key];
    }
  });

  return values;
}

export function convertValuesByExtraOptions(
  values: DetailedCarOrderValues,
  extraOptions: ExtraOptionsType
) {
  if (!extraOptions.creditAmount) {
    delete values.creditAmount;
  }

  if (!extraOptions.carInCredit) {
    delete values.myCarBrand;
    delete values.myCarModel;
    delete values.myCarYear;
  }

  return values;
}

export function hasErrors(errors: {}) {
  return Object.keys(errors).length > 0;
}

export function ResetErrors({
  isFirstStep,
  setErrors,
}: {
  isFirstStep: boolean;
  setErrors: (errors: FormikErrors<{}>) => void;
}) {
  useUpdateEffect(() => {
    if (isFirstStep) {
      setErrors({});
    }
  }, [isFirstStep]);

  return null;
}
