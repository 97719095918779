import styled, { css } from 'styled-components';

import { ButtonPrimary } from '@/components/CarCard/components/Button';
import { media } from '@/utils/mixin';

export const Component = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

/** current step **/

export const StepTitle = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.black};
`;

export const Buttons = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${media.mobile(css`
    flex-direction: column;
    margin-top: 25px;
  `)}
`;

export const StyledNextButton = styled(ButtonPrimary)<{ isLoading?: boolean }>`
  position: relative;
  min-width: 194.92px;
  padding: 15px 57px;
  border-radius: 14px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 12px;

  ${(props) =>
    props.disabled
      ? css`
          color: ${(props) => props.theme.black100} !important;
          background: ${(props) => props.theme.gray400_1} !important;
          border: 1px solid ${(props) => props.theme.gray400_2} !important;
        `
      : null}

  ${media.mobile(css`
    min-height: 52px;
    border-radius: 20px;
    margin-left: 0;

    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  `)}
  
  ${(props) =>
    props.isLoading
      ? css`
          pointer-events: none;
          min-height: 50px;
        `
      : null}
`;

export const StyledPrevButton = styled(StyledNextButton)`
  background: none;
  border: 1px solid ${(props) => props.theme.black_alpha20};
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    order: 2;
    margin-top: 10px;
  `)}
`;

export const FormRow = styled.div<{ hasFullHeight?: boolean }>`
  position: relative;
  display: flex;
  margin-top: 35px;
  height: ${(props) => (props.hasFullHeight ? 'calc(100% - 35px)' : 'auto')};

  & > div {
    &:not(:first-child) {
      margin-left: 24px;

      ${media.mobile(css`
        margin-top: 35px;
        margin-left: 0;
      `)}
    }
  }

  ${media.mobile(css`
    flex-direction: column;

    textarea {
      min-height: 160px;
    }
  `)}
`;

export const Form = styled.form``;

export const StepsWrapper = styled.div`
  margin: 32px 0 20px;
`;

export const CarPreviewWrapper = styled.div`
  margin-top: 15px;
`;
