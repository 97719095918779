import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import {
  CabinetBrandsPayload,
  CarsItemType,
  ServiceCarsPayload,
  UserBonusesType,
  UserBookmarksType,
  UserProfileModel,
  UserProfilePreview,
} from '@/services/users/typings';
import { AppState, AppThunk } from '@/store/store';
import {
  getCabinetBrands,
  getServiceCars,
  getUserBonusesPreview,
  getUserBookmarks,
  getUserCars,
  getUserProfile,
  getUserProfilePreview,
  userApiService,
} from '@/services/users/users-service';
import { stepsData } from '@/modules/Cabinet/modules/CabinetService/constants';
import { ServiceChooseCarFormFields } from '@/modules/Cabinet/modules/CabinetService/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/types';
import { ServiceChooseCarCenterFormFields } from '@/modules/Cabinet/modules/CabinetService/components/MainContent/components/ServiceFormView/components/ServiceChooseCarCenterForm/types';
import { getProfilePercentFullness } from '@/modules/Cabinet/utils';
import { ServiceChooseServiceFields } from '@/modules/Cabinet/modules/CabinetService/components/MainContent/components/ServiceFormView/components/ServiceChooseServiceForm/types';
import { ServiceChooseProgramTOFields } from '@/modules/Cabinet/modules/CabinetService/components/MainContent/components/ServiceFormView/components/ServiceChooseProgramTOForm/types';
import { getServiceOfflineBrands } from '@/services/service/service-service';
import { ServiceCarCentersResponseType } from '@/services/service/typings';

interface State {
  profile: Nullable<UserProfilePreview>;
  personalData: Nullable<UserProfileModel>;
  bonuses: {
    preview: Nullable<UserBonusesType>;
  };
  cars: CarsItemType[];
  brands: CabinetBrandsPayload[];
  bookmarks: UserBookmarksType[];
  service: {
    activeStep: string;

    chooseCarData: Nullable<ServiceChooseCarFormFields>;
    chooseCarCenterData: Nullable<ServiceChooseCarCenterFormFields>;
    chooseService: Nullable<ServiceChooseServiceFields>;
    chooseProgramTO: Nullable<ServiceChooseProgramTOFields>;
    isServiceFormSubmit: boolean;

    cars: ServiceCarsPayload[];
    offlineBrands: ServiceCarCentersResponseType[];
  };
}

const initialState: State = {
  profile: null,
  personalData: null,
  bonuses: { preview: null },
  cars: [],
  brands: [],
  bookmarks: [],
  service: {
    activeStep: stepsData.steps[0],

    chooseCarData: null,
    chooseCarCenterData: null,
    chooseService: null,
    chooseProgramTO: null,
    isServiceFormSubmit: false,

    cars: [],
    offlineBrands: [],
  },
};

const slice = createSlice({
  name: 'cabinet',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<UserProfilePreview>) => {
      state.profile = action.payload;
    },
    setPersonalData: (
      state,
      action: PayloadAction<Nullable<UserProfileModel>>
    ) => {
      state.personalData = action.payload;
    },
    setBonusesPreview: (
      state,
      action: PayloadAction<Nullable<UserBonusesType>>
    ) => {
      state.bonuses.preview = action.payload;
    },
    setCars: (state, action: PayloadAction<CarsItemType[]>) => {
      state.cars = action.payload;
    },
    setBookmarks: (state, action: PayloadAction<UserBookmarksType[]>) => {
      state.bookmarks = action.payload;
    },
    setServiceActiveStep: (state, action: PayloadAction<string>) => {
      state.service.activeStep = action.payload;
    },
    setServiceChooseCarData: (
      state,
      action: PayloadAction<Nullable<ServiceChooseCarFormFields>>
    ) => {
      state.service.chooseCarData = action.payload;
    },
    setServiceChooseService: (
      state,
      action: PayloadAction<Nullable<ServiceChooseServiceFields>>
    ) => {
      state.service.chooseService = action.payload;
    },
    setServiceChooseProgramTO: (
      state,
      action: PayloadAction<Nullable<ServiceChooseProgramTOFields>>
    ) => {
      state.service.chooseProgramTO = action.payload;
    },
    setOfflineBrands: (
      state,
      action: PayloadAction<ServiceCarCentersResponseType[]>
    ) => {
      state.service.offlineBrands = action.payload;
    },
    setServiceChooseCarCenterData: (
      state,
      action: PayloadAction<Nullable<ServiceChooseCarCenterFormFields>>
    ) => {
      state.service.chooseCarCenterData = action.payload;
    },
    setServiceFormSubmit: (state, action: PayloadAction<boolean>) => {
      state.service.isServiceFormSubmit = action.payload;
    },
    setBrands: (state, action: PayloadAction<CabinetBrandsPayload[]>) => {
      state.brands = action.payload;
    },
    setServiceCars: (state, action: PayloadAction<ServiceCarsPayload[]>) => {
      state.service.cars = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setProfile,
  setPersonalData,
  setBonusesPreview,
  setCars,
  setBrands,
  setServiceActiveStep,
  setServiceChooseCarData,
  setServiceChooseService,
  setServiceChooseProgramTO,
  setOfflineBrands,
  setServiceChooseCarCenterData,
  setServiceFormSubmit,
  setServiceCars,
} = slice.actions;

/** Thunks **/

export function getUserProfilePreviewThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const userProfilePreview = await getUserProfilePreview();
      dispatch(setProfile(userProfilePreview));
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function getUserPersonalDataThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const personalData = await getUserProfile();

      // update token
      const tokenNew = personalData?.token ?? '';
      userApiService.setAccessToken(tokenNew);

      if (personalData) {
        dispatch(
          setPersonalData({
            ...personalData,
            profilePercentFullness: getProfilePercentFullness(personalData),
          })
        );
      } else {
        dispatch(setPersonalData(null));
        return;
      }
    } catch (error) {
      dispatch(setPersonalData(null));
      return Promise.reject(error);
    }
  };
}

export function getUserBonusesPreviewThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const bonuses = await getUserBonusesPreview();

      if (bonuses) {
        dispatch(setBonusesPreview(bonuses));
      } else {
        dispatch(setBonusesPreview(null));
        return;
      }
    } catch (error) {
      dispatch(setBonusesPreview(null));
      return Promise.reject(error);
    }
  };
}

export function getUserCarsThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const cars = await getUserCars().then((response) =>
        response.sort((firstCar, secondCar) => {
          const firstOwner = firstCar.isOwner;
          const secondOwner = secondCar.isOwner;

          return firstOwner === secondOwner ? 0 : firstOwner ? -1 : 1;
        })
      );

      dispatch(setCars(cars));
    } catch (error) {
      dispatch(setCars([]));
      return Promise.reject(error);
    }
  };
}

export function getBrandsThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const brands = await getCabinetBrands();

      dispatch(setBrands(brands));
    } catch (error) {
      dispatch(setBrands([]));
      return Promise.reject(error);
    }
  };
}

export function getOfflineBrandsThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const brands = await getServiceOfflineBrands();

      dispatch(setOfflineBrands(brands));
    } catch (error) {
      dispatch(setOfflineBrands([]));
      return Promise.reject(error);
    }
  };
}

export function getServiceCarsThunk(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const cars = await getServiceCars();

      dispatch(setServiceCars(cars));
    } catch (error) {
      dispatch(setServiceCars([]));
      return Promise.reject(error);
    }
  };
}

/** Selectors **/

export function getCabinetProfile(
  state: AppState
): Nullable<UserProfilePreview> {
  return state.cabinet.profile;
}

export function getCabinetPersonalData(
  state: AppState
): Nullable<UserProfileModel> {
  return state.cabinet.personalData;
}

export function getCabinetBonusesPreview(
  state: AppState
): Nullable<UserBonusesType> {
  return state.cabinet.bonuses.preview;
}

export function getCabinetCars(state: AppState): CarsItemType[] {
  return state.cabinet.cars;
}

export function getServiceActiveStep(state: AppState): string {
  return state.cabinet.service.activeStep;
}

export function getServiceChooseCarData(
  state: AppState
): Nullable<ServiceChooseCarFormFields> {
  return state.cabinet.service.chooseCarData;
}

export function getServiceChooseService(
  state: AppState
): Nullable<ServiceChooseServiceFields> {
  return state.cabinet.service.chooseService;
}

export function getServiceChooseProgramTO(
  state: AppState
): Nullable<ServiceChooseProgramTOFields> {
  return state.cabinet.service.chooseProgramTO;
}

export function getOfflineBrands(
  state: AppState
): ServiceCarCentersResponseType[] {
  return state.cabinet.service.offlineBrands;
}

export function getServiceChooseCarCenterData(
  state: AppState
): Nullable<ServiceChooseCarCenterFormFields> {
  return state.cabinet.service.chooseCarCenterData;
}

export function getServiceFormSubmit(state: AppState): boolean {
  return state.cabinet.service.isServiceFormSubmit;
}

export function getBrandsData(state: AppState): CabinetBrandsPayload[] {
  return state.cabinet.brands;
}

export function getServiceCarsData(state: AppState): ServiceCarsPayload[] {
  return state.cabinet.service.cars;
}
