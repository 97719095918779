import React, { useState } from 'react';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import styled from 'styled-components';
import { FormikHandlers } from 'formik/dist/types';

import { useUpdateEffect } from '@tager/web-core';

import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import { BaseApplicationOption } from '@/components/modals/DetailedCarModal/components/Application/common.types';
import TextInput from '@/components/TextInput';
import Loader from '@/components/Loader';
import { CheckboxAgreement } from '@/components/Checkbox';
import { DetailedCarOrderValues } from '@/services/leads/typings';
import { OptionType } from '@/typings/common';
import Select from '@/components/Select';

export interface Props {
  touched?: FormikTouched<DetailedCarOrderValues>;
  options: Array<BaseApplicationOption>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FormikHandlers['handleBlur'];
  values: DetailedCarOrderValues;
  dealers: OptionType[];
  errors: FormikErrors<DetailedCarOrderValues>;
  isSubmitting?: boolean;
  hasErrors: boolean;
  setFieldValue: FormikHelpers<DetailedCarOrderValues>['setFieldValue'];
}

function checkValues(
  options: Array<BaseApplicationOption>,
  values: DetailedCarOrderValues
): boolean {
  return (
    (options[0].checked && !values.creditAmount) ||
    (options[1].checked &&
      (!values.myCarBrand || !values.myCarModel || !values.myCarYear))
  );
}

function isCreditChecked(options: Array<BaseApplicationOption>) {
  return options.find((item) => item.value === 1)?.checked;
}

function isAutoChecked(options: Array<BaseApplicationOption>) {
  return options.find((item) => item.value === 2)?.checked;
}

function isCustomPriceChecked(options: Array<BaseApplicationOption>) {
  return options.find((item) => item.value === 3)?.checked;
}

function getDiggetedValue(value: string): string {
  value = value.replace(/[^0-9]*\.?[^0-9]+/g, '');
  value = value.replace(/.+?(?=\D|$)/, function (f) {
    return f.replace(/(\d)(?=(?:\d\d\d)+$)/g, '$1 ');
  });

  return value;
}

function sliceLength(value: string): string {
  if (value.length > 4) {
    return value.slice(0, 4);
  }

  return value;
}

function Request({
  options,
  onChange,
  onBlur,
  errors,
  values,
  dealers,
  hasErrors,
  touched,
  isSubmitting,
  setFieldValue,
}: Props) {
  const [userAgreement, setUserAgreement] = useState(false);
  const [dealer, setDealer] = useState<OptionType>({ label: '', value: '' });

  useUpdateEffect(() => {
    setFieldValue('dealer', Number(dealer.value));
  }, [dealer]);

  return (
    <Component>
      {isCreditChecked(options) && (
        <S.FormRow>
          <TextInput
            id="creditAmount"
            name="creditAmount"
            value={values.creditAmount ? String(values.creditAmount) : ''}
            label="Сумма кредита"
            placeholder="Сумма кредита"
            onChange={(event) => {
              event.target.value = getDiggetedValue(event.target.value);
              onChange(event);
            }}
            onBlur={onBlur}
            disabled={isSubmitting}
            badge={'BYN'}
            errorMessage={
              touched?.creditAmount && errors.creditAmount
                ? errors.creditAmount
                : undefined
            }
          />
        </S.FormRow>
      )}
      {isAutoChecked(options) && (
        <S.FormRow>
          <TextInput
            id="brand"
            name="myCarBrand"
            value={values.myCarBrand || ''}
            label="Марка"
            placeholder="Марка"
            onChange={onChange}
            onBlur={onBlur}
            disabled={isSubmitting}
            errorMessage={
              touched?.myCarBrand && errors.myCarBrand ? ' ' : undefined
            }
          />

          <TextInput
            id="model"
            name="myCarModel"
            value={values.myCarModel || ''}
            label="Модель"
            placeholder="Модель"
            onChange={onChange}
            onBlur={onBlur}
            disabled={isSubmitting}
            errorMessage={
              touched?.myCarModel && errors.myCarModel ? ' ' : undefined
            }
          />

          <TextInput
            id="year"
            name="myCarYear"
            value={String(values.myCarYear || '')}
            label="Год выпуска"
            placeholder="Год выпуска"
            onChange={(event) => {
              event.target.value = event.target.value.replace(/[^0-9+]/, '');
              event.target.value = sliceLength(event.target.value);
              onChange(event);
            }}
            onBlur={onBlur}
            disabled={isSubmitting}
            errorMessage={
              touched?.myCarYear && errors.myCarYear ? ' ' : undefined
            }
            type={'number'}
            inputMode={'numeric'}
          />
        </S.FormRow>
      )}
      {isCustomPriceChecked(options) && (
        <S.FormRow>
          <TextInput
            id="user-price-field"
            name="myPrice"
            value={values.myPrice || ''}
            label="Предложить свою цену"
            onChange={(event) => {
              event.target.value = getDiggetedValue(event.target.value);
              onChange(event);
            }}
            onBlur={onBlur}
            disabled={isSubmitting}
            errorMessage={touched?.myPrice && errors.myPrice ? ' ' : undefined}
            badge={'BYN'}
            inputMode={'numeric'}
          />
        </S.FormRow>
      )}

      <S.FormRow>
        <TextInput
          id="name"
          name="name"
          label="Имя"
          placeholder="Имя"
          value={values.name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isSubmitting}
          errorMessage={touched?.name && errors.name ? ' ' : undefined}
        />
        <TextInput
          name="phone"
          label="Телефон"
          placeholder="+375 (__) ___-__-__"
          value={values.phone}
          onChange={(event) => {
            setFieldValue('phone', event.target.value);
          }}
          onBlur={onBlur}
          disabled={isSubmitting}
          errorMessage={touched?.phone && errors.phone ? ' ' : undefined}
        />
      </S.FormRow>

      <S.FormRow>
        <Select
          label="Автоцентр"
          value={dealer}
          options={dealers}
          onChange={setDealer}
          errorMessage={
            touched?.dealer && errors.dealer ? errors.dealer : undefined
          }
        />
      </S.FormRow>

      <S.FormRow>
        <CheckboxAgreement
          checked={userAgreement}
          onChange={() => setUserAgreement(!userAgreement)}
        />
      </S.FormRow>

      <S.Buttons>
        <S.StyledNextButton
          isLoading={isSubmitting}
          type="submit"
          disabled={hasErrors || checkValues(options, values) || !userAgreement}
        >
          {!isSubmitting ? 'Отправить' : <Loader color="#fff" />}
        </S.StyledNextButton>
      </S.Buttons>
    </Component>
  );
}

export default Request;

const Component = styled(S.Component)``;
