import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, FormikTouched } from 'formik';
import { FormikHandlers } from 'formik/dist/types';

import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import { media } from '@/utils/mixin';
import Loader from '@/components/Loader';
import { CheckboxAgreement } from '@/components/Checkbox';
import TextArea from '@/components/TextArea';
import TextInput from '@/components/TextInput';
import { ITestDriveFormUserValuesData } from '@/components/modals/DetailedCarModal/components/Application/common.types';

interface Props {
  touched?: FormikTouched<ITestDriveFormUserValuesData>;
  values: ITestDriveFormUserValuesData;
  onPrevStep?: () => void;
  errors: FormikErrors<ITestDriveFormUserValuesData>;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onBlur?: FormikHandlers['handleBlur'];
  hasErrors: boolean;
  isSubmitting: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

function ContactFormInner({
  touched,
  values,
  onChange,
  onBlur,
  onPrevStep,
  errors,
  isSubmitting,
  hasErrors,
  setFieldValue,
}: Props) {
  const [userAgreement, setUserAgreement] = useState(false);

  const handleUserAgreementChange = () => {
    setUserAgreement(!userAgreement);
  };

  const isDisabled = hasErrors || !userAgreement;

  return (
    <Component>
      <Inner>
        <GroupInputs>
          <S.FormRow>
            <TextInput
              id="name"
              name="name"
              label="Имя"
              placeholder="Имя"
              value={values.name}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isSubmitting}
              errorMessage={touched?.name && errors.name ? ' ' : undefined}
            />
          </S.FormRow>
          <S.FormRow>
            <TextInput
              name="phone"
              label="Телефон"
              placeholder="+375 (__) ___-__-__"
              value={values.phone}
              onChange={(event) => {
                setFieldValue?.('phone', event.target.value);
              }}
              onBlur={onBlur}
              disabled={isSubmitting}
              errorMessage={touched?.phone && errors.phone ? ' ' : undefined}
            />
          </S.FormRow>
          <S.FormRow>
            <TextInput
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              value={values.email}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isSubmitting}
              errorMessage={touched?.email && errors.email ? ' ' : undefined}
            />
          </S.FormRow>
        </GroupInputs>
        <GroupInputs>
          <S.FormRow hasFullHeight>
            <TextArea
              name="comment"
              label="Комментарий"
              placeholder="Комментарий"
              value={values.comment}
              onBlur={onBlur}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </S.FormRow>
        </GroupInputs>
      </Inner>
      <S.FormRow>
        <Agreement>
          <CheckboxAgreement
            checked={userAgreement}
            onChange={handleUserAgreementChange}
          />
        </Agreement>
      </S.FormRow>
      <S.Buttons>
        <S.StyledPrevButton
          type="button"
          onClick={onPrevStep}
          disabled={isSubmitting}
        >
          назад
        </S.StyledPrevButton>
        <S.StyledNextButton
          isLoading={isSubmitting}
          type="submit"
          disabled={isDisabled}
        >
          {!isSubmitting ? 'Отправить' : <Loader color="#fff" />}
        </S.StyledNextButton>
      </S.Buttons>
    </Component>
  );
}

export default ContactFormInner;

const Component = styled.div``;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Agreement = styled.div`
  display: flex;
`;

const GroupInputs = styled.div`
  width: calc(50% - 12px);

  ${media.mobile(css`
    width: 100%;
  `)}
`;
