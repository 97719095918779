import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@tager/web-core';

import { AppState } from '@/store/store';
import { OfflineValuationUserChoiceModel } from '@/modules/ValueCar/ValueCar.types';

interface StateType {
  status: FetchStatus;
  data: OfflineValuationUserChoiceModel;
}

const initialState: StateType = {
  status: 'SUCCESS',
  data: {
    brand: '',
    model: '',
    year: '',
    dealerId: '',
    date: '',
    time: '',
  },
};

const slice = createSlice({
  name: 'offlineValuation',
  initialState,
  reducers: {
    setOfflineChosenBrand(state, action: PayloadAction<string>) {
      state.data.brand = action.payload;
    },

    setOfflineChosenModel(state, action: PayloadAction<string>) {
      state.data.model = action.payload;
    },

    setOfflineChosenYear(state, action: PayloadAction<string>) {
      state.data.year = action.payload;
    },

    setOfflineChosenDealerId(state, action: PayloadAction<string>) {
      state.data.dealerId = action.payload;
    },

    setOfflineChosenDate(state, action: PayloadAction<string>) {
      state.data.date = action.payload;
    },

    setOfflineChosenTime(state, action: PayloadAction<string>) {
      state.data.time = action.payload;
    },

    setOfflineUserChoice(
      state,
      action: PayloadAction<OfflineValuationUserChoiceModel>
    ) {
      state.data = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setOfflineChosenBrand,
  setOfflineChosenModel,
  setOfflineChosenYear,
  setOfflineChosenDealerId,
  setOfflineUserChoice,
  setOfflineChosenDate,
  setOfflineChosenTime,
} = slice.actions;

export function selectOfflineValuationUserChoice(
  state: AppState
): OfflineValuationUserChoiceModel {
  return state.offlineValuationUserData.data;
}
