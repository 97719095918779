import React from 'react';
import styled, { css } from 'styled-components';

import CloseIcon from '@/assets/svg/close-24dp-white.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

function CloseButton(props: Props) {
  return (
    <StyledButton type="button" {...props}>
      <CloseIcon />
    </StyledButton>
  );
}

export default CloseButton;

const StyledButton = styled.button`
  z-index: 10000;
  position: fixed;
  top: 30px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${colors.main};
  border-radius: 50%;
  transition: 0.15s;

  ${media.tablet(css`
    width: 30px;
    height: 30px;
  `)}

  &:hover {
    background: ${colors.main100};
  }
`;
