import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { colors } from '@/constants/theme';

interface Props {
  isFixedPosition?: boolean;
  color?: string;
}

function Loader({ isFixedPosition = false, color = colors.main }: Props) {
  return <Component isFixedPosition={isFixedPosition} color={color} />;
}

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Component = styled.div<{ isFixedPosition?: boolean; color: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 40px;
  height: 40px;
  z-index: 500;

  &:after {
    position: absolute;
    content: ' ';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid ${(props) => props.color};
    border-color: ${(props) => props.color} transparent
      ${(props) => props.color} transparent;
    animation: ${rotateAnimation} 1.2s linear infinite;
  }

  ${({ isFixedPosition }) =>
    isFixedPosition &&
    css`
      position: fixed;
    `}
`;

export default Loader;
