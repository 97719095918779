import ApplicationForCar from '@/components/modals/DetailedCarModal/components/Application/components/ApplicationForCar';
import TestDriveOffline from '@/components/modals/DetailedCarModal/components/Application/components/TestDriveOffline';
import TestDrive from '@/components/modals/DetailedCarModal/components/Application/components/TestDrive';
import { CarsType } from '@/typings/common';
import { ApplicationModalType } from '@/components/modals/DetailedCarModal/DetailedCarModal';

export const BASE_APPLICATION_TITLE: Record<
  CarsType,
  Record<ApplicationModalType, string>
> = {
  amp: {
    APPLICATION: 'Получить предложение',
    TEST_DRIVE: 'Посмотреть авто',
    TEST_DRIVE_OFFLINE: 'Посмотреть авто',
    CUSTOM_PRICE: 'Своя цена',
  },
  new: {
    APPLICATION: 'Получить предложение',
    TEST_DRIVE: 'Запись на тест-драйв',
    CUSTOM_PRICE: 'Своя цена',
    TEST_DRIVE_OFFLINE: 'Запись на тест-драйв',
  },
};

export const applicationFormMap = {
  new: {
    APPLICATION: ApplicationForCar,
    CUSTOM_PRICE: ApplicationForCar,
    TEST_DRIVE: TestDrive,
    TEST_DRIVE_OFFLINE: TestDriveOffline,
  },
  amp: {
    APPLICATION: ApplicationForCar,
    CUSTOM_PRICE: ApplicationForCar,
    TEST_DRIVE: TestDrive,
    TEST_DRIVE_OFFLINE: TestDriveOffline,
  },
} as const;
