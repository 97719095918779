import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  CalculatorInitialValuesResponse,
  CreditTypes,
  StaffCalculatorValues,
  StaffProductCardData,
  StaffProductCardsParams,
  StaffProductsCardsData,
} from '@/services/finance/typings';
import { sortData } from '@/modules/StaffCreditPage/components/StaffCredit/components/MainHeader/components/Sort/helpers';
import {
  loadCalculatorInitialValues,
  loadStaffProductCardsData,
} from '@/services/finance/finance-service';
import { DetailedCarData } from '@/services/stock/typings';
import { OptionType } from '@/typings/common';

export enum MainHeaderActiveTab {
  Offers = 'OFFERS',
  Compare = 'COMPARE',
}

export interface CarHistory extends DetailedCarData {
  datetime: string;
}

interface State {
  offerCalculator: {
    isOffersOpened: boolean;

    calculatorInitialValues: Nullable<CalculatorInitialValuesResponse>;
    calculatorValues: StaffCalculatorValues;

    tabs: {
      activeTab: CreditTypes;
    };

    carPrice: number;
  };
  selectedCar: Nullable<CarHistory>;
  mainHeader: {
    tabs: {
      activeTab: MainHeaderActiveTab;
    };

    sort: {
      activeSort: OptionType;
    };
  };
  staffProductCardsData: Nullable<StaffProductsCardsData>;
  staffProductCompareCardsData: StaffProductCardData[];
  isLoading: boolean;
  isRecentlyViewed: boolean;
}

const initialState: State = {
  offerCalculator: {
    isOffersOpened: false,

    calculatorInitialValues: null,
    calculatorValues: {
      carCost: 0,
      prepayment: 0,
      period: 0,
    },

    tabs: {
      activeTab: 'CREDIT',
    },

    carPrice: 0,
  },
  selectedCar: null,
  mainHeader: {
    tabs: {
      activeTab: MainHeaderActiveTab.Offers,
    },

    sort: {
      activeSort: sortData[0],
    },
  },
  staffProductCardsData: null,
  staffProductCompareCardsData: [],
  isLoading: false,
  isRecentlyViewed: false,
};

const slice = createSlice({
  name: 'staff-calculator',
  initialState,
  reducers: {
    setCalculatorActiveTab: (state, action: PayloadAction<CreditTypes>) => {
      state.offerCalculator.tabs.activeTab = action.payload;
    },
    setSelectedCar: (state, action: PayloadAction<Nullable<CarHistory>>) => {
      state.selectedCar = action.payload;
    },
    setOffersOpened: (state, action: PayloadAction<boolean>) => {
      state.offerCalculator.isOffersOpened = action.payload;
    },
    setCalculatorInitialValues: (
      state,
      action: PayloadAction<Nullable<CalculatorInitialValuesResponse>>
    ) => {
      state.offerCalculator.calculatorInitialValues = action.payload;
    },
    setCalculatorValues: (
      state,
      action: PayloadAction<StaffCalculatorValues>
    ) => {
      state.offerCalculator.calculatorValues = action.payload;
    },
    setCalculatorCarCost: (state, action: PayloadAction<number>) => {
      state.offerCalculator.calculatorValues.carCost = action.payload;
    },
    setCalculatorPrepayment: (state, action: PayloadAction<number>) => {
      state.offerCalculator.calculatorValues.prepayment = action.payload;
    },
    setCalculatorPeriod: (state, action: PayloadAction<number>) => {
      state.offerCalculator.calculatorValues.period = action.payload;
    },
    setCarPrice: (state, action: PayloadAction<number>) => {
      state.offerCalculator.carPrice = action.payload;
    },
    setMainHeaderActiveTab: (
      state,
      action: PayloadAction<MainHeaderActiveTab>
    ) => {
      state.mainHeader.tabs.activeTab = action.payload;
    },
    setMainHeaderActiveSort: (state, action: PayloadAction<OptionType>) => {
      state.mainHeader.sort.activeSort = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setRecentlyViewed: (state, action: PayloadAction<boolean>) => {
      state.isRecentlyViewed = action.payload;
    },
    setStaffProductCards: (
      state,
      action: PayloadAction<Nullable<StaffProductsCardsData>>
    ) => {
      state.staffProductCardsData = action.payload;
    },
    setStaffProductsCompareCards: (
      state,
      action: PayloadAction<StaffProductCardData[]>
    ) => {
      state.staffProductCompareCardsData = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setCalculatorActiveTab,
  setSelectedCar,
  setOffersOpened,
  setCalculatorInitialValues,
  setCalculatorValues,
  setCalculatorCarCost,
  setCalculatorPrepayment,
  setCalculatorPeriod,
  setCarPrice,
  setMainHeaderActiveTab,
  setMainHeaderActiveSort,
  setLoading,
  setRecentlyViewed,
  setStaffProductCards,
  setStaffProductsCompareCards,
} = slice.actions;

/** Thunks **/

export const fetchCalculatorInitialValuesThunk =
  (
    carId: string
  ): AppThunk<Promise<Nullable<CalculatorInitialValuesResponse>>> =>
  async (dispatch) => {
    try {
      const calculatorInitialValues = await loadCalculatorInitialValues(carId);
      dispatch(setCalculatorInitialValues(calculatorInitialValues));
      return calculatorInitialValues;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const fetchStaffProductCardsThunk =
  (
    params: StaffProductCardsParams
  ): AppThunk<Promise<Nullable<StaffProductsCardsData>>> =>
  async (dispatch) => {
    try {
      const staffProductCards = await loadStaffProductCardsData(params);
      dispatch(setStaffProductCards(staffProductCards));
      return staffProductCards;
    } catch (error) {
      return Promise.reject(error);
    }
  };

/** Selectors **/

export function getCalculatorActiveTab(state: AppState): CreditTypes {
  return state.staffCalculator.offerCalculator.tabs.activeTab;
}

export function getSelectedCar(state: AppState): Nullable<CarHistory> {
  return state.staffCalculator.selectedCar;
}

export function getOffersOpened(state: AppState): boolean {
  return state.staffCalculator.offerCalculator.isOffersOpened;
}

export function getCalculatorInitialValues(
  state: AppState
): Nullable<CalculatorInitialValuesResponse> {
  return state.staffCalculator.offerCalculator.calculatorInitialValues;
}

export function getCalculatorValues(
  state: AppState
): Nullable<StaffCalculatorValues> {
  return state.staffCalculator.offerCalculator.calculatorValues;
}

export function getMainHeaderActiveTab(state: AppState): MainHeaderActiveTab {
  return state.staffCalculator.mainHeader.tabs.activeTab;
}

export function getMainHeaderActiveSort(state: AppState): OptionType {
  return state.staffCalculator.mainHeader.sort.activeSort;
}

export function getLoading(state: AppState): boolean {
  return state.staffCalculator.isLoading;
}

export function getRecentlyViewed(state: AppState): boolean {
  return state.staffCalculator.isRecentlyViewed;
}

export function getStaffProductCards(
  state: AppState
): Nullable<StaffProductsCardsData> {
  return state.staffCalculator.staffProductCardsData;
}

export function getStaffProductCompareCards(
  state: AppState
): StaffProductCardData[] {
  return state.staffCalculator.staffProductCompareCardsData;
}

export function getCarPrice(state: AppState): number {
  return state.staffCalculator.offerCalculator.carPrice;
}
