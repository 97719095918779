import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import {
  DetailedCarData,
  CarStockItemResponse,
  SimilarCarsDataParams,
} from '@/services/stock/typings';
import { AppState, AppThunk } from '@/store/store';
import {
  fetchDetailedCarData,
  fetchSimilarCarsData,
} from '@/services/stock/stock-service';
import { CarsType } from '@/typings/common';
import { BlogPost } from '@/typings/model';
import { getCarBlog } from '@/services/requests';
import {
  CalculatorInitialValuesResponse,
  ProductCardsData,
  ProductCardsParams,
} from '@/services/finance/typings';
import {
  loadCalculatorInitialValues,
  loadProductCardsData,
} from '@/services/finance/finance-service';
import { defaultPrepayment } from '@/components/modals/CreditModal/constants';

interface State {
  status: FetchStatus;
  data: Nullable<DetailedCarData>;
  similarCars: {
    status: FetchStatus;
    data: CarStockItemResponse[];
  };
  carBlog: {
    status: FetchStatus;
    data: BlogPost[];
  };
  credit: {
    initialValues: {
      status: FetchStatus;
      data: Nullable<CalculatorInitialValuesResponse>;
    };
    products: {
      status: FetchStatus;
      data: Nullable<ProductCardsData>;
    };
    prepayment: number;
    period: number;
  };
}

const initialState: State = {
  status: 'IDLE',
  data: null,
  similarCars: {
    status: 'IDLE',
    data: [],
  },
  carBlog: {
    status: 'IDLE',
    data: [],
  },
  credit: {
    initialValues: {
      status: 'IDLE',
      data: null,
    },
    products: {
      status: 'IDLE',
      data: null,
    },
    prepayment: defaultPrepayment,
    period: 0,
  },
};

const slice = createSlice({
  name: 'detailed-car',
  initialState,
  reducers: {
    setDetailedCarLoading: (state) => {
      state.status = 'LOADING';
    },
    setDetailedCarSuccess: (
      state,
      action: PayloadAction<Nullable<DetailedCarData>>
    ) => {
      state.data = action.payload;
      state.status = 'SUCCESS';
    },
    setDetailedCarFailure: (state) => {
      state.status = 'FAILURE';
    },

    setSimilarCarsLoading: (state) => {
      state.similarCars.status = 'LOADING';
    },
    setSimilarCarsSuccess: (
      state,
      action: PayloadAction<CarStockItemResponse[]>
    ) => {
      state.similarCars.data = action.payload;
      state.similarCars.status = 'SUCCESS';
    },
    setSimilarCarsFailure: (state) => {
      state.similarCars.status = 'FAILURE';
    },

    setCarBlogLoading: (state) => {
      state.carBlog.status = 'LOADING';
    },
    setCarBlogSuccess: (state, action: PayloadAction<BlogPost[]>) => {
      state.carBlog.data = action.payload;
      state.carBlog.status = 'SUCCESS';
    },
    setCarBlogFailure: (state) => {
      state.carBlog.status = 'FAILURE';
    },

    setCreditInitialValuesLoading: (state) => {
      state.credit.initialValues.status = 'LOADING';
    },
    setCreditInitialValuesSuccess: (
      state,
      action: PayloadAction<CalculatorInitialValuesResponse>
    ) => {
      state.credit.initialValues.data = action.payload;
      state.credit.initialValues.status = 'SUCCESS';
    },
    setCreditInitialValuesFailure: (state) => {
      state.credit.initialValues.status = 'FAILURE';
    },
    setCreditProductsLoading: (state) => {
      state.credit.products.status = 'LOADING';
    },
    setCreditProductsSuccess: (
      state,
      action: PayloadAction<ProductCardsData>
    ) => {
      state.credit.products.data = action.payload;
      state.credit.products.status = 'SUCCESS';
    },
    setCreditProductsFailure: (state) => {
      state.credit.products.status = 'FAILURE';
    },
    setCreditPrepayment: (state, action: PayloadAction<number>) => {
      state.credit.prepayment = action.payload;
    },
    setCreditPeriod: (state, action: PayloadAction<number>) => {
      state.credit.period = action.payload;
    },

    resetDetailedCarState: () => initialState,
  },
});

export default slice.reducer;

export const {
  setDetailedCarLoading,
  setDetailedCarSuccess,
  setDetailedCarFailure,

  setSimilarCarsLoading,
  setSimilarCarsSuccess,
  setSimilarCarsFailure,

  setCarBlogLoading,
  setCarBlogSuccess,
  setCarBlogFailure,

  setCreditInitialValuesLoading,
  setCreditInitialValuesSuccess,
  setCreditInitialValuesFailure,
  setCreditProductsLoading,
  setCreditProductsSuccess,
  setCreditProductsFailure,
  setCreditPrepayment,
  setCreditPeriod,

  resetDetailedCarState,
} = slice.actions;

/** Thunks **/

export const getDetailedCarThunk =
  (carType: CarsType, carId: string): AppThunk<Promise<DetailedCarData>> =>
  async (dispatch) => {
    dispatch(setDetailedCarLoading());

    try {
      const carData = await fetchDetailedCarData(carType, carId);
      dispatch(setDetailedCarSuccess(carData));

      return carData;
    } catch (error) {
      dispatch(setDetailedCarFailure());

      return Promise.reject(error);
    }
  };

export const getSimilarCarsThunk =
  (
    carType: CarsType,
    carId: string,
    params?: SimilarCarsDataParams
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(setSimilarCarsLoading());

    try {
      const similarCarsData = await fetchSimilarCarsData(
        carType,
        carId,
        params
      );

      dispatch(setSimilarCarsSuccess(similarCarsData));
    } catch (error) {
      dispatch(setSimilarCarsFailure());

      return Promise.reject(error);
    }
  };

export const getCarBlogThunk =
  (carType: CarsType, carId: string): AppThunk<Promise<BlogPost[]>> =>
  async (dispatch) => {
    dispatch(setCarBlogLoading());

    try {
      const carBlogData = await getCarBlog(carType, carId);
      dispatch(setCarBlogSuccess(carBlogData));

      return carBlogData;
    } catch (error) {
      dispatch(setCarBlogFailure());

      return Promise.reject(error);
    }
  };

export const getCreditInitialValuesThunk =
  (carId: string): AppThunk<Promise<CalculatorInitialValuesResponse>> =>
  async (dispatch) => {
    dispatch(setCreditInitialValuesLoading());

    try {
      const calculatorInitialValues = await loadCalculatorInitialValues(carId);
      dispatch(setCreditInitialValuesSuccess(calculatorInitialValues));

      return calculatorInitialValues;
    } catch (error) {
      dispatch(setCreditInitialValuesFailure());

      return Promise.reject(error);
    }
  };

export const getCreditProductsThunk =
  (params: ProductCardsParams): AppThunk<Promise<ProductCardsData>> =>
  async (dispatch) => {
    dispatch(setCreditProductsLoading());

    try {
      const productCardsData = await loadProductCardsData(params);
      dispatch(setCreditProductsSuccess(productCardsData));

      return productCardsData;
    } catch (error) {
      dispatch(setCreditProductsFailure());

      return Promise.reject(error);
    }
  };

/** Selectors **/

export function selectDetailedCarData(state: AppState) {
  return state.detailedCar.data;
}

export function selectDetailedCarStatus(state: AppState) {
  return state.detailedCar.status;
}

export function selectSimilarCarsData(state: AppState) {
  return state.detailedCar.similarCars.data;
}

export function selectCarBlogData(state: AppState) {
  return state.detailedCar.carBlog.data;
}

export function selectCreditInitialValuesData(state: AppState) {
  return state.detailedCar.credit.initialValues.data;
}

export function selectCreditProductsData(state: AppState) {
  return state.detailedCar.credit.products.data;
}

export function selectCreditProductsStatus(state: AppState) {
  return state.detailedCar.credit.products.status;
}

export function selectCreditPrepayment(state: AppState) {
  return state.detailedCar.credit.prepayment;
}

export function selectCreditPeriod(state: AppState) {
  return state.detailedCar.credit.period;
}
