import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { applicationFormMap } from '@/components/modals/DetailedCarModal/components/Application/common.constans';
import { CarProps } from '@/components/modals/DetailedCarModal/components/Application/common.types';
import SuccessForm from '@/components/Forms/SuccessForm';
import { CarsType } from '@/typings/common';
import { ApplicationModalType } from '@/components/modals/DetailedCarModal/DetailedCarModal';

interface Props {
  type: ApplicationModalType;
  typeState: CarsType;
  car: CarProps;
  closeModal: () => void;
  formInnerRef: React.RefObject<HTMLDivElement>;
  success: boolean;
  setSuccess: Dispatch<SetStateAction<boolean>>;
}

function Application({
  type,
  typeState,
  car,
  closeModal,
  formInnerRef,
  success,
  setSuccess,
}: Props) {
  const el = applicationFormMap[typeState][type];

  function onSuccess() {
    setSuccess(true);
  }

  const form = React.createElement(el, {
    type,
    car,
    typeState,
    closeModal,
    onSuccess,
  });

  return (
    <Component ref={formInnerRef} isSuccess={success}>
      {!success ? form : <SuccessForm />}
    </Component>
  );
}

const Component = styled.div<{ isSuccess?: Props['success'] }>`
  position: relative;
  width: 100%;
  height: 100% !important;
  min-height: 50px;
  padding: 25px 40px;

  ${media.mobile(css`
    padding: 20px;
  `)};

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export default Application;
