import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import { BASE_APPLICATION_TITLE } from '@/components/modals/DetailedCarModal/components/Application/common.constans';
import {
  BaseApplicationOption,
  BaseComponentProps,
} from '@/components/modals/DetailedCarModal/components/Application/common.types';
import RegistrationType from '@/components/modals/DetailedCarModal/components/Application/components/common/RegistationTypes';
import Request from '@/components/modals/DetailedCarModal/components/Application/components/ApplicationForCar/steps/Request';
import {
  handleValidateOrderForm,
  hasErrors,
} from '@/components/modals/DetailedCarModal/components/Application/common.helpers';
import {
  getInitialOptions,
  INITIAL_FORM_VALUES,
} from '@/components/modals/DetailedCarModal/components/Application/components/ApplicationForCar/ApplicationForCar.constans';
import { submitOrderForm } from '@/components/modals/DetailedCarModal/components/Application/common.requests';
import { CarPreviewWrapper } from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import CarPreview from '@/components/CarPreview';
import { googleEvent } from '@/utils/events';
import { DetailedCarOrderValues } from '@/services/leads/typings';
import useSettingItem from '@/hooks/useSettingItem';

function ApplicationForCar({
  type,
  typeState,
  car,
  onSuccess,
}: BaseComponentProps) {
  const [options, setOptions] = useState<Array<BaseApplicationOption>>(
    getInitialOptions(typeState, type)
  );
  const applicationGoogleEvent = useSettingItem(
    typeState === 'new'
      ? 'DETAILED_CAR_NEW_APPLICATION_GOOGLE_EVENT'
      : 'DETAILED_CAR_AMP_APPLICATION_GOOGLE_EVENT'
  );
  const applicationMyPriceGoogleEvent = useSettingItem(
    'DETAILED_CAR_AMP_MY_PRICE_GOOGLE_EVENT'
  );

  function onChange(option: BaseApplicationOption) {
    const newOptions = options.map((prevOption) => {
      if (prevOption.value === option.value) {
        return { ...prevOption, checked: !prevOption.checked };
      } else {
        return prevOption;
      }
    });
    setOptions(newOptions);
  }

  function submitGtagEvent(values: DetailedCarOrderValues) {
    if (values.myPrice && applicationMyPriceGoogleEvent) {
      googleEvent(applicationMyPriceGoogleEvent, car.googleEventParams);
    } else if (applicationGoogleEvent) {
      googleEvent(applicationGoogleEvent, car.googleEventParams);
    }
  }

  function onSubmit(
    values: DetailedCarOrderValues,
    formikHelper: FormikHelpers<DetailedCarOrderValues>
  ) {
    formikHelper.setSubmitting(true);

    values.myPrice = values.myPrice
      ? Number(values.myPrice.toString().replaceAll(' ', ''))
      : null;
    values.creditAmount = values.creditAmount
      ? Number(values.creditAmount.toString().replaceAll(' ', ''))
      : null;
    values.myCarYear = values.myCarYear ? Number(values.myCarYear) : null;

    submitOrderForm(
      {
        ...values,
        type: typeState,
        id: Number(car.id),
      },
      {
        creditAmount: options[0].checked,
        carInCredit: options[1].checked,
      }
    )
      .then(() => {
        submitGtagEvent(values);
        onSuccess();
      })
      .catch((error) => {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<DetailedCarOrderValues>;
        formikHelper.setErrors(errorMap);
        console.error(error);
      });

    formikHelper.setSubmitting(false);
  }

  return (
    <S.Component>
      <S.StepTitle>{BASE_APPLICATION_TITLE[typeState][type]}</S.StepTitle>

      <CarPreviewWrapper>
        <CarPreview
          image={car.image}
          name={car.name}
          price={car.price}
          currency="BYN"
          body={car.bodyType}
          carType={typeState}
        />
      </CarPreviewWrapper>

      <RegistrationType
        applicationModalType={type}
        options={options}
        onChange={onChange}
      />

      <Formik<DetailedCarOrderValues>
        onSubmit={onSubmit}
        validate={(values) => handleValidateOrderForm(values, car.price ?? 0)}
        initialValues={INITIAL_FORM_VALUES}
        validateOnChange
      >
        {({
          handleSubmit,
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <>
              <S.Form onSubmit={handleSubmit}>
                <Request
                  errors={errors}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  options={options}
                  dealers={car.dealers ?? []}
                  isSubmitting={isSubmitting}
                  hasErrors={hasErrors(
                    handleValidateOrderForm(values, car.price ?? 0)
                  )}
                  setFieldValue={setFieldValue}
                />
              </S.Form>
            </>
          );
        }}
      </Formik>
    </S.Component>
  );
}

export default ApplicationForCar;
