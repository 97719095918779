import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@tager/web-core';

import {
  OnlineValuationUserChoiceModel,
  UserChoiceParametersModel,
} from '@/modules/ValueCar/ValueCar.types';
import { AppState } from '@/store/store';

interface StateType {
  status: FetchStatus;
  data: OnlineValuationUserChoiceModel;
}

const initialState: StateType = {
  status: 'SUCCESS',
  data: {
    brand: '',
    model: '',
    year: '',
    bodyType: '',
    generation: '',
    parameters: {
      fuel: '',
      gearBox: '',
      enginePower: '',
      engineVolume: '',
    },
    photo: {
      exterior: {
        front: [],
        back: [],
        left: [],
        right: [],
        underHood: [],
      },
      interior: {
        dashboard: [],
        torpedo: [],
        interiorBack: [],
        interiorFront: [],
      },
      additionalPhoto: {
        additionalPhoto: [],
      },
      documents: {
        frontSide: [],
        backSide: [],
      },
    },
  },
};

const slice = createSlice({
  name: 'onlineValuation',
  initialState,
  reducers: {
    setOnlineChosenBrand(state, action: PayloadAction<string>) {
      state.data.brand = action.payload;
    },

    setOnlineChosenModel(state, action: PayloadAction<string>) {
      state.data.model = action.payload;
    },

    setOnlineChosenYear(state, action: PayloadAction<string>) {
      state.data.year = action.payload;
    },

    setOnlineChosenBodyType(state, action: PayloadAction<string>) {
      state.data.bodyType = action.payload;
    },

    setOnlineChosenGeneration(state, action: PayloadAction<string>) {
      state.data.generation = action.payload;
    },

    setOnlineChosenParameters(
      state,
      action: PayloadAction<UserChoiceParametersModel>
    ) {
      state.data.parameters = action.payload;
    },

    setOnlinePhoto(
      state,
      action: PayloadAction<{ category: string; view: string; value: string }>
    ) {
      const { category, view, value } = action.payload;

      // @ts-ignore
      state.data.photo[category][view].push(value);
    },

    deleteOnlinePhoto(
      state,
      action: PayloadAction<{ category: string; view: string; id: number }>
    ) {
      const { category, view, id } = action.payload;
      // @ts-ignore
      state.data.photo[category][view] = [
        // @ts-ignore
        ...state.data.photo[category][view].filter(
          (item: any) => item.size !== id
        ),
      ];
    },

    setOnlineUserChoice(
      state,
      action: PayloadAction<OnlineValuationUserChoiceModel>
    ) {
      state.data = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setOnlineChosenBrand,
  setOnlineChosenModel,
  setOnlineChosenYear,
  setOnlineChosenBodyType,
  setOnlineChosenGeneration,
  setOnlineChosenParameters,
  setOnlineUserChoice,
} = slice.actions;

export function selectOnlineUserChoice(
  state: AppState
): OnlineValuationUserChoiceModel {
  return state.onlineValuationUserData.data;
}
