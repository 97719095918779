export enum CarsStockBody {
  Sedan = 'SEDAN',
  LiftBack = 'LIFT_BACK',
  Crossover = 'CROSSOVER',
  Minibus = 'MINIBUS',
  Van = 'VAN',
  Suv = 'SUV',
  Hatchback = 'HATCHBACK',
  Coupe = 'COUPE',
  Pickup = 'PICKUP',
  StationWagon = 'STATION_WAGON',
  Minivan = 'MINIVAN',
  Bus = 'BUS',
  Chassis = 'CHASSIS',
  Cabriolet = 'CABRIOLET',
  ShootingBrake = 'SHOOTING_BRAKE',
}

export enum CarsStockGearbox {
  Manual = 'MANUAL',
  Automatic = 'AUTOMATIC',
  Robot = 'ROBOT',
  DSG = 'DSG',
  Variator = 'VARIATOR',
  Reductor = 'REDUCTOR',
}

export enum CarsStockFlag {
  SuperPrice = 'SUPER_PRICE',
  OnWarranty = 'ON_WARRANTY',
  Approved = 'APPROVED',
  PartnerCar = 'PARTNER_CAR',
  Available = 'AVAILABLE',
  OnStock = 'ON_STOCK',
  Credit = 'CREDIT',
}

export enum CarsStockVat {
  NoTax = 'NO_TAX',
  Tax20 = 'TAX_20',
}

export enum CarsStockWheel {
  Front = 'FRONT',
  Back = 'BACK',
  Full = 'FULL',
}

export enum CarsStockFuel {
  Petrol = 'PETROL',
  Diesel = 'DIESEL',
  Gas = 'GAS',
  Electric = 'ELECTRIC',
  Hybrid = 'HYBRID',
}

export enum CarsStockBattery {
  LithiumIon = 'LITHIUM_ION',
  LithiumIron = 'LITHIUM_IRON',
  LithiumNickel = 'LITHIUM_NICKEL',
  LithiumPolymeric = 'LITHIUM_POLYMERIC',
  MetalAir = 'METAL_AIR',
}

export enum CarsStockAvailableStatus {
  Available = 'AVAILABLE',
  Booked = 'BOOKED',
}

export enum CarsStockStatusStock {
  InStock = 'IN_STOCK',
  OnWay = 'ON_WAY',
  InProduction = 'IN_PRODUCTION',
  Preorder = 'PRE_ORDER',
}

export enum CarsStockGrade {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
}

export enum CarsStockSort {
  Date = 'date',
  Popular = 'popular',
  PriceAsc = 'price_asc',
  PriceDesc = 'price_desc',
  MileageAsc = 'mileage_asc',
  MileageDesc = 'mileage_desc',
  Rand = 'rand',
  RandomBrands = 'rand-brands',
}
