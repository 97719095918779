import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import {
  ServiceBrandLandingResponseType,
  ServiceBrandServiceLandingResponseType,
  ServiceBrandsResponseType,
  ServiceDealersResponseType,
  ServiceSingleLandingResponseType,
} from '@/services/service/typings';
import { AppState, AppThunk } from '@/store/store';
import {
  getServiceBrandLandingData,
  getServiceBrandsData,
  getServiceBrandServiceLandingData,
  getServiceDealersData,
  getServiceSingleLandingData,
} from '@/services/service/service-service';
import { ServiceBlogPost } from '@/typings/model';
import { loadServicePosts } from '@/services/requests';

export interface State {
  dealers: {
    status: FetchStatus;
    data: ServiceDealersResponseType[];
  };
  brands: {
    status: FetchStatus;
    data: ServiceBrandsResponseType[];
  };
  posts: {
    status: FetchStatus;
    data: ServiceBlogPost[];
  };
  brand: {
    landing: {
      status: FetchStatus;
      data: Nullable<ServiceBrandLandingResponseType>;
    };
    service: {
      status: FetchStatus;
      data: Nullable<ServiceBrandServiceLandingResponseType>;
    };
  };
  singleService: {
    status: FetchStatus;
    data: Nullable<ServiceSingleLandingResponseType>;
  };
}

export const initialState: State = {
  dealers: {
    status: 'IDLE',
    data: [],
  },
  brands: {
    status: 'IDLE',
    data: [],
  },
  posts: {
    status: 'IDLE',
    data: [],
  },
  brand: {
    landing: {
      status: 'IDLE',
      data: null,
    },
    service: {
      status: 'IDLE',
      data: null,
    },
  },
  singleService: {
    status: 'IDLE',
    data: null,
  },
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServicePostsLoading(state) {
      state.posts.status = 'LOADING';
    },
    setServicePostsSuccess(state, action: PayloadAction<ServiceBlogPost[]>) {
      state.posts.data = action.payload;
      state.posts.status = 'SUCCESS';
    },
    setServicePostsFailure(state) {
      state.posts.status = 'FAILURE';
    },

    setServiceDealersLoading(state) {
      state.dealers.status = 'LOADING';
    },
    setServiceDealersSuccess(
      state,
      action: PayloadAction<ServiceDealersResponseType[]>
    ) {
      state.dealers.data = action.payload;
      state.dealers.status = 'SUCCESS';
    },
    setServiceDealersFailure(state) {
      state.dealers.status = 'FAILURE';
    },

    setServiceBrandsLoading(state) {
      state.brands.status = 'LOADING';
    },
    setServiceBrandsSuccess(
      state,
      action: PayloadAction<ServiceBrandsResponseType[]>
    ) {
      state.brands.data = action.payload;
      state.brands.status = 'SUCCESS';
    },
    setServiceBrandsFailure(state) {
      state.brands.status = 'FAILURE';
    },

    setServiceBrandLandingLoading(state) {
      state.brand.landing.status = 'LOADING';
    },
    setServiceBrandLandingSuccess(
      state,
      action: PayloadAction<ServiceBrandLandingResponseType>
    ) {
      state.brand.landing.data = action.payload;
      state.brand.landing.status = 'SUCCESS';
    },
    setServiceBrandLandingFailure(state) {
      state.brand.landing.status = 'FAILURE';
    },

    setServiceSingleLandingLoading(state) {
      state.singleService.status = 'LOADING';
    },
    setServiceSingleLandingSuccess(
      state,
      action: PayloadAction<ServiceSingleLandingResponseType>
    ) {
      state.singleService.data = action.payload;
      state.singleService.status = 'SUCCESS';
    },
    setServiceSingleLandingFailure(state) {
      state.singleService.status = 'FAILURE';
    },

    setServiceBrandServiceLandingLoading(state) {
      state.brand.service.status = 'LOADING';
    },
    setServiceBrandServiceLandingSuccess(
      state,
      action: PayloadAction<ServiceBrandServiceLandingResponseType>
    ) {
      state.brand.service.data = action.payload;
      state.brand.service.status = 'SUCCESS';
    },
    setServiceBrandServiceLandingFailure(state) {
      state.brand.service.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  setServiceDealersLoading,
  setServiceDealersSuccess,
  setServiceDealersFailure,

  setServiceBrandsLoading,
  setServiceBrandsSuccess,
  setServiceBrandsFailure,

  setServiceBrandLandingLoading,
  setServiceBrandLandingSuccess,
  setServiceBrandLandingFailure,

  setServiceSingleLandingLoading,
  setServiceSingleLandingSuccess,
  setServiceSingleLandingFailure,

  setServiceBrandServiceLandingLoading,
  setServiceBrandServiceLandingSuccess,
  setServiceBrandServiceLandingFailure,

  setServicePostsLoading,
  setServicePostsSuccess,
  setServicePostsFailure,
} = slice.actions;

/** Thunks **/

export const getServicePostsDataThunk =
  (brandTags?: string[]): AppThunk<Promise<ServiceBlogPost[]>> =>
  async (dispatch): Promise<ServiceBlogPost[]> => {
    dispatch(setServicePostsLoading());
    try {
      const servicePostsData = await loadServicePosts(brandTags);
      dispatch(setServicePostsSuccess(servicePostsData));

      return servicePostsData;
    } catch (error) {
      dispatch(setServicePostsFailure());
      return Promise.reject(error);
    }
  };

export const getServiceDealersDataThunk =
  (): AppThunk<Promise<ServiceDealersResponseType[]>> =>
  async (dispatch): Promise<ServiceDealersResponseType[]> => {
    dispatch(setServiceDealersLoading());
    try {
      const serviceDealersData = await getServiceDealersData();
      dispatch(setServiceDealersSuccess(serviceDealersData));

      return serviceDealersData;
    } catch (error) {
      dispatch(setServiceDealersFailure());
      return Promise.reject(error);
    }
  };

export const getServiceBrandsDataThunk =
  (): AppThunk<Promise<ServiceBrandsResponseType[]>> =>
  async (dispatch): Promise<ServiceBrandsResponseType[]> => {
    dispatch(setServiceBrandsLoading());
    try {
      const serviceBrands = await getServiceBrandsData();
      dispatch(setServiceBrandsSuccess(serviceBrands));
      return serviceBrands;
    } catch (error) {
      dispatch(setServiceBrandsFailure());
      return Promise.reject(error);
    }
  };

export const getServiceBrandLandingDataThunk =
  (brandAlias: string): AppThunk<Promise<ServiceBrandLandingResponseType>> =>
  async (dispatch): Promise<ServiceBrandLandingResponseType> => {
    dispatch(setServiceBrandLandingLoading());
    try {
      const serviceBrandLanding = await getServiceBrandLandingData(brandAlias);
      dispatch(setServiceBrandLandingSuccess(serviceBrandLanding));

      return serviceBrandLanding;
    } catch (error) {
      dispatch(setServiceBrandLandingFailure());
      return Promise.reject(error);
    }
  };

export const getServiceSingleLandingDataThunk =
  (
    serviceAlias: string[]
  ): AppThunk<Promise<ServiceSingleLandingResponseType>> =>
  async (dispatch): Promise<ServiceSingleLandingResponseType> => {
    dispatch(setServiceSingleLandingLoading());
    try {
      const serviceSingleLanding = await getServiceSingleLandingData(
        serviceAlias
      );
      dispatch(setServiceSingleLandingSuccess(serviceSingleLanding));

      return serviceSingleLanding;
    } catch (error) {
      dispatch(setServiceSingleLandingFailure());
      return Promise.reject(error);
    }
  };

export const getServiceBrandServiceLandingDataThunk =
  (
    brandAlias: string,
    serviceAlias: string[]
  ): AppThunk<Promise<ServiceBrandServiceLandingResponseType>> =>
  async (dispatch): Promise<ServiceBrandServiceLandingResponseType> => {
    dispatch(setServiceBrandServiceLandingLoading());
    try {
      const serviceBrandServiceLanding =
        await getServiceBrandServiceLandingData(brandAlias, serviceAlias);
      dispatch(
        setServiceBrandServiceLandingSuccess(serviceBrandServiceLanding)
      );

      return serviceBrandServiceLanding;
    } catch (error) {
      dispatch(setServiceBrandServiceLandingFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const getServiceDealers = (
  state: AppState
): ServiceDealersResponseType[] => {
  return state.service.dealers.data;
};

export const getServiceDealersWithBodyRepair = (
  state: AppState
): ServiceDealersResponseType[] => {
  return state.service.dealers.data.filter((item) => item.withBodyRepair);
};

export const getServiceBrands = (
  state: AppState
): ServiceBrandsResponseType[] => {
  return state.service.brands.data;
};

export const getServiceBrandLanding = (
  state: AppState
): Nullable<ServiceBrandLandingResponseType> => {
  return state.service.brand.landing.data;
};

export const getServicePosts = (state: AppState): ServiceBlogPost[] => {
  return state.service.posts.data;
};

export const getServiceBrandServiceLanding = (
  state: AppState
): Nullable<ServiceBrandServiceLandingResponseType> => {
  return state.service.brand.service.data;
};

export const getServiceSingleLanding = (
  state: AppState
): Nullable<ServiceSingleLandingResponseType> => {
  return state.service.singleService.data;
};
