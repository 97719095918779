import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { loadCarLocations } from '@/services/requests';
import { TestDriveLocation } from '@/typings/model';

interface State {
  carLocationsStatus: FetchStatus;
  carLocations: Nullable<TestDriveLocation[]>;
}

const initialState: State = {
  carLocationsStatus: 'IDLE',
  carLocations: null,
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    fetchCarLocationsLoading: (state) => {
      state.carLocationsStatus = 'LOADING';
    },
    fetchCarLocationsSuccess: (
      state,
      action: PayloadAction<TestDriveLocation[]>
    ) => {
      state.carLocationsStatus = 'SUCCESS';
      state.carLocations = action.payload;
    },
    fetchCarLocationsFailure: (state) => {
      state.carLocationsStatus = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  fetchCarLocationsLoading,
  fetchCarLocationsSuccess,
  fetchCarLocationsFailure,
} = slice.actions;

/** Thunks **/

export const fetchCarLocationsThunk =
  (id: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(fetchCarLocationsLoading());
    try {
      const carLocations = await loadCarLocations(id);
      dispatch(fetchCarLocationsSuccess(carLocations));
    } catch (error) {
      dispatch(fetchCarLocationsFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectCarLocations = (state: AppState) =>
  state.purchase.carLocations;
