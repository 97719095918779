import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { loadDealers } from '@/services/requests';
import { Dealer } from '@/typings/model';

interface State {
  status: FetchStatus;
  data: Dealer[];
}

const initialState: State = {
  status: 'IDLE',
  data: [],
};

const slice = createSlice({
  name: 'dealers',
  initialState,
  reducers: {
    fetchDealersLoading: (state) => {
      state.status = 'LOADING';
    },
    fetchDealersSuccess: (state, action: PayloadAction<Dealer[]>) => {
      state.status = 'SUCCESS';
      state.data = action.payload;
    },
    fetchDealersFailure: (state) => {
      state.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const { fetchDealersLoading, fetchDealersSuccess, fetchDealersFailure } =
  slice.actions;

/** Thunks **/

export const fetchDealersThunk =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(fetchDealersLoading());
    try {
      const response = await loadDealers();
      dispatch(fetchDealersSuccess(response.data));
    } catch (error) {
      dispatch(fetchDealersFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectDealers = (state: AppState) => state.dealers.data;
