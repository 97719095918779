import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ButtonStyles } from '@/components/CarCard/components/common.styles';

import Button from './Button';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

function ButtonPrimary(props: Props) {
  return <Component {...props} />;
}

export default ButtonPrimary;

const Component = styled(Button)`
  ${ButtonStyles}
`;
