import { DetailedCarOrderValues } from '@/services/leads/typings';
import { submitDetailedCarOrder } from '@/services/leads/leads-service';

import {
  convertFormValues,
  convertValuesByExtraOptions,
} from './common.helpers';
import { ExtraOptionsType } from './common.types';

export async function submitOrderForm(
  values: DetailedCarOrderValues,
  extraOptions: ExtraOptionsType
) {
  const preValues = convertValuesByExtraOptions(values, extraOptions);
  return submitDetailedCarOrder(convertFormValues(preValues));
}
