import React from 'react';

import { ModalProvider } from '@tager/web-components';

import { ThemeProvider } from '@/theme';

export const AppProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider>
      <ModalProvider>{children}</ModalProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
