import Router from 'next/router';
import moment from 'moment';

import { Nullable } from '@tager/web-core';

import {
  CabinetBrandsPayload,
  ServiceCarsPayload,
  UserProfileModel,
} from '@/services/users/typings';
import { OptionType } from '@/typings/common';
import {
  IdNameType,
  ServiceCarCentersResponseType,
} from '@/services/service/typings';

export const handleRedirectToServicePage = () => {
  Router.push('/personal/service');
};

export const getProfilePercentFullness = (
  profileInformation: Nullable<UserProfileModel>
) => {
  if (!profileInformation) {
    return 0;
  }

  const {
    socialGoogle,
    socialYandex,
    socialVkontakte,
    name,
    patronymic,
    lastName,
    birthdate,
    gender,
    emails,
    phones,
  } = profileInformation;

  const dataArray = [
    socialGoogle,
    socialYandex,
    socialVkontakte,
    name,
    patronymic,
    lastName,
    birthdate,
    gender,
    emails[0],
    phones[0],
  ];

  const dataArrayLength = dataArray.length;
  const dataArrayNoEmpty = dataArray.filter(Boolean).length;

  return Math.ceil((100 / dataArrayLength) * dataArrayNoEmpty);
};

export const handleSortCabinetDataTableByDate = (
  activeValue: string,
  list: any
) => {
  if (activeValue !== 'new') {
    return list.sort(
      (firstDate: any, secondDate: any) =>
        new Date(firstDate.date).valueOf() - new Date(secondDate.date).valueOf()
    );
  } else {
    return list.sort(
      (firstDate: any, secondDate: any) =>
        new Date(secondDate.date).valueOf() - new Date(firstDate.date).valueOf()
    );
  }
};

export const getHoursAndMinutes = (
  seconds: number
): { hours: number; minutes: number } => {
  const hoursAndMinutes = moment.utc(seconds * 1000).format('H:m');
  const splitHoursAndMinutes = hoursAndMinutes.split(':');

  return {
    hours: Number(splitHoursAndMinutes[0]),
    minutes: Number(splitHoursAndMinutes[1]),
  };
};

export const getHoursAndMinutesFormattedText = (
  hours: number,
  minutes: number
): string => {
  return `${hours ? hours + ' ч' : ''} ${minutes ? minutes + ' мин' : ''}`;
};

export const DefaultOption: OptionType = { value: '', label: '' };

export const getDealerOptions = (
  brandId: number,
  brands: CabinetBrandsPayload[]
): OptionType[] => {
  if (brandId) {
    const foundDealers = brands.find((brand) => brand.id === brandId)?.dealers;

    if (!foundDealers || foundDealers.length === 0) {
      return getFullDealerOptions(brands);
    }

    return foundDealers.map(({ name }) => {
      return { value: name, label: name };
    });
  } else {
    return getFullDealerOptions(brands);
  }
};

export const getDealerId = (
  brands: CabinetBrandsPayload[],
  searchDealer: string
): number => {
  if (!brands || brands.length === 0) {
    return 0;
  }

  const foundDealer = brands
    .map(({ dealers }) =>
      dealers.find((dealer) => dealer.name === searchDealer)
    )
    .filter(Boolean);

  return foundDealer[0]?.id ?? 0;
};

export const getFullDealerOptions = (
  brands: CabinetBrandsPayload[]
): OptionType[] => {
  if (brands && brands.length !== 0) {
    return [
      ...new Map(
        brands
          .map(({ dealers }) => {
            return dealers.map(({ name }) => {
              return { value: name, label: name };
            });
          })
          .flat(1)
          .map((option) => [option.value, option])
      ).values(),
    ];
  } else {
    return [DefaultOption];
  }
};

export const getCarById = (
  id: number,
  cars: ServiceCarsPayload[]
): ServiceCarsPayload | undefined => {
  return cars.find((car) => car.id === id);
};

export const getInitialOptionSelect = (initValue?: OptionType): OptionType => {
  if (initValue) {
    return { value: initValue.value, label: initValue.label };
  }

  return DefaultOption;
};

export const getSelectOption = (
  options: IdNameType[] | ServiceCarCentersResponseType[]
): OptionType[] => {
  if (!options) return [DefaultOption];

  return options.map((option) => ({ value: option.id, label: option.name }));
};
