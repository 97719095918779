import React from 'react';

import StockSpecialFilterCheckbox from '@/components/StockSpecialFilterCheckbox';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getSelectedFilters,
  getStockFilterParams,
  setCarAvailable,
  setPage,
} from '@/store/reducers/cars-stock';
import { OptionType } from '@/typings/common';
import { getSpecialFilterOptionById } from '@/modules/CarsStock/components/common.helpers';
import { CarsStockFlag } from '@/services/stock/enums';

export function AvailableFilter() {
  const dispatch = useTypedDispatch();
  const selectedAvailable =
    useTypedSelector(getSelectedFilters).selectedParams.selectedSpecials
      .selectedAvailable;
  const stockFilterParams = useTypedSelector(getStockFilterParams);

  const availableOption = getSpecialFilterOptionById(
    stockFilterParams,
    CarsStockFlag.Available
  );

  const handleChangeAvailable = (available: OptionType) => {
    if (!selectedAvailable.value) {
      dispatch(setCarAvailable(available));
    } else {
      dispatch(setCarAvailable({ label: '', value: '' }));
    }
    dispatch(setPage(1));
  };

  return (
    <StockSpecialFilterCheckbox
      checked={!!selectedAvailable.value}
      option={availableOption}
      onChange={handleChangeAvailable}
    />
  );
}
