import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@tager/web-core';

import { IAmpLocationsList } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getAmpLocations, getZoomosBrands } from '@/services/requests';
import {
  CarParametersModel,
  RateCarDataModel,
} from '@/modules/ValueCar/ValueCar.types';

interface StateType {
  status: FetchStatus;
  dealersStatus: FetchStatus;
  data: RateCarDataModel;
}

const initialState: StateType = {
  status: 'SUCCESS',
  dealersStatus: 'SUCCESS',
  data: {
    brands: [],
    models: [],
    years: [],
    bodyTypes: [],
    generations: [],
    parameters: [],
    dealers: null,
  },
};

const slice = createSlice({
  name: 'selections',
  initialState,
  reducers: {
    setDealersRequestStatusPending(state) {
      state.dealersStatus = 'LOADING';
    },

    setDealersRequestStatusFailed(state) {
      state.dealersStatus = 'FAILURE';
    },

    setDealersSuccess(state, action: PayloadAction<IAmpLocationsList>) {
      state.data.dealers = action.payload;
      state.dealersStatus = 'SUCCESS';
    },

    setRateCarDataRequestStatusPending(state) {
      state.status = 'LOADING';
    },

    setRateCarDataRequestStatusFailed(state) {
      state.status = 'FAILURE';
    },

    setRateCarDataSuccess(state, action: PayloadAction<string[]>) {
      state.data.brands = action.payload;
      state.status = 'SUCCESS';
    },

    setRateCarModelsPending(state) {
      state.status = 'LOADING';
    },

    setRateCarModels(state, action: PayloadAction<Array<string>>) {
      state.data.models = action.payload;
      state.status = 'SUCCESS';
    },

    setRateCarYearsPending(state) {
      state.status = 'LOADING';
    },

    setRateCarYears(state, action: PayloadAction<Array<number>>) {
      state.data.years = action.payload.reverse();
      state.status = 'SUCCESS';
    },

    setRateCarBodyTypesPending(state) {
      state.status = 'LOADING';
    },

    setRateCarBodyTypes(state, action: PayloadAction<Array<string>>) {
      state.data.bodyTypes = action.payload;
      state.status = 'SUCCESS';
    },

    setRateCarGenerationsPending(state) {
      state.status = 'LOADING';
    },

    setRateCarGenerations(state, action: PayloadAction<Array<string>>) {
      state.data.generations = action.payload;
      state.status = 'SUCCESS';
    },

    setRateCarParametersPending(state) {
      state.status = 'LOADING';
    },

    setRateCarParameters(
      state,
      action: PayloadAction<Array<CarParametersModel>>
    ) {
      state.data.parameters = action.payload;
      state.status = 'SUCCESS';
    },
  },
});

export const {
  setDealersRequestStatusPending,
  setDealersRequestStatusFailed,
  setDealersSuccess,
  setRateCarDataRequestStatusPending,
  setRateCarDataRequestStatusFailed,
  setRateCarDataSuccess,
  setRateCarModels,
  setRateCarModelsPending,
  setRateCarYears,
  setRateCarYearsPending,
  setRateCarBodyTypes,
  setRateCarBodyTypesPending,
  setRateCarGenerations,
  setRateCarGenerationsPending,
  setRateCarParameters,
  setRateCarParametersPending,
} = slice.actions;

export const getRateCarDataThunk =
  (options?: { shouldValidate?: boolean }): AppThunk =>
  async (dispatch, getState): Promise<string[]> => {
    dispatch(setRateCarDataRequestStatusPending());

    try {
      const response = await getZoomosBrands();
      dispatch(setRateCarDataSuccess(response));
      return response;
    } catch (error) {
      console.log('error');
      dispatch(setRateCarDataRequestStatusFailed());
      return Promise.reject(error);
    }
  };

export const getDealersThunk =
  (options?: { shouldValidate?: boolean }): AppThunk =>
  async (dispatch, getState): Promise<IAmpLocationsList> => {
    dispatch(setDealersRequestStatusPending());

    try {
      const response = await getAmpLocations();
      dispatch(setDealersSuccess(response));
      return response;
    } catch (error) {
      dispatch(setDealersRequestStatusFailed());
      return Promise.reject(error);
    }
  };

export default slice.reducer;

export function selectRateCarsData(state: AppState): RateCarDataModel {
  return state.rateCarData.data;
}
