import React from 'react';

import Checkbox from '@/components/Checkbox';
import useSettingItem from '@/hooks/useSettingItem';

type Props = {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
};

const CheckboxAgreement = ({
  checked,
  onChange,
  className,
  disabled,
}: Props) => {
  const content = useSettingItem('CONTACTS_AGREEMENT_HTML');

  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      label={content || 'Empty'}
      className={className}
    />
  );
};

export default CheckboxAgreement;
