import { IncomingMessage } from 'http';

import { cookie, Nullable } from '@tager/web-core';

import { OptionType, UtmType } from '@/typings/common';

export const getUtmFromCookie = (req?: IncomingMessage): Nullable<UtmType> => {
  const utmFromCookie = cookie.get('utm', req);

  return utmFromCookie ? JSON.parse(utmFromCookie) : null;
};

export const getCityFromCookie = (
  req?: IncomingMessage
): Nullable<OptionType> => {
  const cityFromCookie = cookie.get('selectedCity', req);

  return cityFromCookie ? JSON.parse(cityFromCookie) : null;
};
