import * as Yup from 'yup';
import { omit } from 'lodash';

import {
  CallbackFormFieldsConfig,
  CallbackFormFieldsSchema,
} from './CallbackForm.types';

export const getResultCallbackFormFields = (
  values: CallbackFormFieldsSchema,
  fieldsConfig?: CallbackFormFieldsConfig
): CallbackFormFieldsSchema => {
  const paths: Array<keyof CallbackFormFieldsSchema> = [];

  if (!fieldsConfig?.dealers?.length) {
    paths.push('dealer');
  }

  if (!fieldsConfig?.withMessage) {
    paths.push('message');
  }

  return omit(values, paths) as CallbackFormFieldsSchema;
};

export const initialCallbackFormValues: CallbackFormFieldsSchema = {
  name: '',
  phone: '',
  dealer: { label: '', value: '' },
  message: '',
};

export const validationCallbackFormSchema: Yup.SchemaOf<CallbackFormFieldsSchema> =
  Yup.object().shape({
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    dealer: Yup.object({
      label: Yup.string().required('Выберите автоцентр'),
      value: Yup.string().required('Выберите автоцентр'),
    }),
    message: Yup.string(),
  });
