import React from 'react';

import StockSpecialFilterCheckbox from '@/components/StockSpecialFilterCheckbox';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getSelectedFilters,
  getStockFilterParams,
  setCarGuarantee,
  setPage,
} from '@/store/reducers/cars-stock';
import { OptionType } from '@/typings/common';
import { getSpecialFilterOptionById } from '@/modules/CarsStock/components/common.helpers';
import { CarsStockFlag } from '@/services/stock/enums';

export function GuaranteeFilter() {
  const dispatch = useTypedDispatch();
  const selectedGuarantee =
    useTypedSelector(getSelectedFilters).selectedParams.selectedSpecials
      .selectedGuarantee;
  const stockFilterParams = useTypedSelector(getStockFilterParams);

  const guaranteeOption = getSpecialFilterOptionById(
    stockFilterParams,
    CarsStockFlag.OnWarranty
  );

  const handleChangeGuarantee = (guarantee: OptionType) => {
    if (!selectedGuarantee.value) {
      dispatch(setCarGuarantee(guarantee));
    } else {
      dispatch(setCarGuarantee({ label: '', value: '' }));
    }
    dispatch(setPage(1));
  };

  return (
    <StockSpecialFilterCheckbox
      checked={!!selectedGuarantee.value}
      option={guaranteeOption}
      onChange={handleChangeGuarantee}
      isMobile
    />
  );
}
