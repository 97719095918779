import { CarsType } from '@/typings/common';

export const INITIAL_FORM_VALUES = {
  type: 'new' as CarsType,
  model: '',
  id: 0,
  dealer: 0,
  name: '',
  phone: '',
};
