import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { IAmpLocationsList } from '@/typings/model';
import { getAmpLocations } from '@/services/requests';

interface State {
  statusDealers: FetchStatus;
  dealers: Nullable<IAmpLocationsList>;
}

const initialState: State = {
  statusDealers: 'IDLE',
  dealers: null,
};

const slice = createSlice({
  name: 'amp-landing',
  initialState,
  reducers: {
    setAmpLandingDealersLoading: (state) => {
      state.statusDealers = 'LOADING';
    },
    setAmpLandingDealersSuccess: (
      state,
      action: PayloadAction<IAmpLocationsList>
    ) => {
      state.dealers = action.payload;
      state.statusDealers = 'SUCCESS';
    },
    setAmpLandingDealersError: (state) => {
      state.statusDealers = 'FAILURE';
    },
  },
});

export const {
  setAmpLandingDealersLoading,
  setAmpLandingDealersSuccess,
  setAmpLandingDealersError,
} = slice.actions;

/** Thunks **/

export const fetchAmpLandingDealersThunk =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(setAmpLandingDealersLoading());
    try {
      const response = await getAmpLocations();

      dispatch(setAmpLandingDealersSuccess(response));
    } catch (error) {
      dispatch(setAmpLandingDealersError());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export function selectAmpLandingDealers(
  state: AppState
): Nullable<IAmpLocationsList> {
  return state.ampLanding.dealers;
}

export default slice.reducer;
