import React from 'react';
import styled, { css } from 'styled-components';

import FilterCheckboxIcon from '@/assets/svg/filter_checkbox.svg';
import FilterCheckboxCheckedIcon from '@/assets/svg/filter_checkbox_checked.svg';
import { colors } from '@/constants/theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

function Checkbox({ checked, ...other }: Props) {
  return (
    <Component checked={checked}>
      {checked ? <FilterCheckboxCheckedIcon /> : <FilterCheckboxIcon />}
      <Input type="checkbox" checked={checked} {...other} />
    </Component>
  );
}

export default Checkbox;

const Component = styled.div<{ checked?: boolean }>`
  position: relative;
  display: inline-flex;
  cursor: pointer;

  ${({ checked }) =>
    checked &&
    css`
      svg {
        rect {
          fill: ${colors.main};
        }
      }
    `}
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  cursor: inherit;
`;
