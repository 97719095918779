import { useRouter } from 'next/router';
import { useState } from 'react';

import { useIsomorphicLayoutEffect } from '@tager/web-core';

import { CustomNextRouter } from '@/typings/common';

export function usePageType(): { pageType: string; template: string } {
  const router = useRouter() as CustomNextRouter;
  const pageProps = router.components?.[router.route].props?.pageProps;

  const [data, setData] = useState({ pageType: '', template: '' });

  useIsomorphicLayoutEffect(() => {
    setData({
      pageType: pageProps?.pageType ?? '',
      template: pageProps?.template ?? '',
    });
  }, [router]);

  return data;
}
