import {
  CarsType,
  OptionType,
  RangeOptionType,
  RangeType,
} from '@/typings/common';
import { StockFilterMultiSelectPartsSelectedOptionType } from '@/components/StockFilterMultiSelectParts/types';
import { ColorOptionType } from '@/components/StockColorPicker/types';

export enum GearboxNames {
  AUTOMAT = 'Автомат',
  MANUAL = 'Механическая',
}

export interface ConvertedFilters {
  brand: OptionType[];
  model: StockFilterMultiSelectPartsSelectedOptionType[];
  complectation: StockFilterMultiSelectPartsSelectedOptionType[];
  gearboxes: StockFilterMultiSelectPartsSelectedOptionType[];
  bodies: OptionType[];
  wheels: OptionType[];
  colors: ColorOptionType[];
  fuelTypes: OptionType[];
  locations: OptionType[];
  batteries: OptionType[];
  grades: OptionType[];
  vat: OptionType;
  price: RangeType;
  mileage: RangeType;
  years: RangeType;
  powerReserve: RangeType;
  batteryCapacity: RangeType;
  engineVolume: RangeOptionType;
  enginePower: RangeType;
  clearance: RangeType;
  seats: RangeType;
  doors: RangeType;
  electricEngines: OptionType[];
  specials: {
    superPrice: OptionType;
    guarantee: OptionType;
    approved: OptionType;
    partnerCar: OptionType;
    available: OptionType;
    onStock: OptionType;
  };
  page: number;
  sort: OptionType;
}

export interface SelectedFilterFromUrlTypes {
  brand?: string[];
  model?: string[];
  complectation?: string[];
  gearboxes?: string[];
  bodies?: string[];
  wheels?: string[];
  colors?: string[];
  fuelTypes?: string[];
  locations?: string[];
  batteries?: string[];
  electricEngines?: string[];
  grades?: string[];
  vat?: string;
  minPrice?: string;
  maxPrice?: string;
  minMileage?: string;
  maxMileage?: string;
  minYear?: string;
  maxYear?: string;
  minEngineVolume?: string;
  maxEngineVolume?: string;
  minPowerReserve?: string;
  maxPowerReserve?: string;
  minBatteryCapacity?: string;
  maxBatteryCapacity?: string;
  minEnginePower?: string;
  maxEnginePower?: string;
  minClearance?: string;
  maxClearance?: string;
  minSeats?: string;
  maxSeats?: string;
  minDoors?: string;
  maxDoors?: string;
  superPrice?: string;
  approved?: string;
  guarantee?: string;
  partnerCar?: string;
  available?: string;
  onStock?: string;
  page?: string;
  sort?: string;
}

export interface ComparisonCarsType {
  carType: CarsType;
  id: number;
}
