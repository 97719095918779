import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';

interface State {
  mobileMenuOpened: boolean;
  mobileUserOpened: boolean;
}

const initialState: State = {
  mobileMenuOpened: false,
  mobileUserOpened: false,
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setMobileMenuOpened: (state, action: PayloadAction<boolean>) => {
      state.mobileMenuOpened = action.payload;
    },
    setMobileUserOpened: (state, action: PayloadAction<boolean>) => {
      state.mobileUserOpened = action.payload;
    },
  },
});

export const { setMobileMenuOpened, setMobileUserOpened } = slice.actions;

/** Selectors **/

export function selectMobileMenuOpened(state: AppState): boolean {
  return state.layout.mobileMenuOpened;
}

export function selectMobileUserOpened(state: AppState): boolean {
  return state.layout.mobileUserOpened;
}

export default slice.reducer;
