import React from 'react';

import StockSpecialFilterCheckbox from '@/components/StockSpecialFilterCheckbox';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getSelectedFilters,
  getStockFilterParams,
  setCarSuperPrice,
  setPage,
} from '@/store/reducers/cars-stock';
import { OptionType } from '@/typings/common';
import { getSpecialFilterOptionById } from '@/modules/CarsStock/components/common.helpers';
import { CarsStockFlag } from '@/services/stock/enums';

export function SuperPriceFilter() {
  const dispatch = useTypedDispatch();
  const selectedSuperPrice =
    useTypedSelector(getSelectedFilters).selectedParams.selectedSpecials
      .selectedSuperPrice;
  const stockFilterParams = useTypedSelector(getStockFilterParams);

  const superPriceOption = getSpecialFilterOptionById(
    stockFilterParams,
    CarsStockFlag.SuperPrice
  );

  const handleChangeSuperPrice = (superPrice: OptionType) => {
    if (!selectedSuperPrice.value) {
      dispatch(setCarSuperPrice(superPrice));
    } else {
      dispatch(setCarSuperPrice({ label: '', value: '' }));
    }
    dispatch(setPage(1));
  };

  return (
    <StockSpecialFilterCheckbox
      checked={!!selectedSuperPrice.value}
      option={superPriceOption}
      onChange={handleChangeSuperPrice}
    />
  );
}
