import React, { useState } from 'react';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import styled from 'styled-components';
import { FormikHandlers } from 'formik/dist/types';

import { useUpdateEffect } from '@tager/web-core';

import * as S from '@/components/modals/DetailedCarModal/components/Application/common.styles';
import TextInput from '@/components/TextInput';
import Loader from '@/components/Loader';
import { CheckboxAgreement } from '@/components/Checkbox';
import { DetailedCarTestDriveOfflineParams } from '@/services/leads/typings';
import { OptionType } from '@/typings/common';
import Select from '@/components/Select';

export interface Props {
  touched?: FormikTouched<DetailedCarTestDriveOfflineParams>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FormikHandlers['handleBlur'];
  values: DetailedCarTestDriveOfflineParams;
  dealers: OptionType[];
  errors: FormikErrors<DetailedCarTestDriveOfflineParams>;
  isSubmitting?: boolean;
  hasErrors: boolean;
  setFieldValue: FormikHelpers<DetailedCarTestDriveOfflineParams>['setFieldValue'];
}

function checkValues(values: DetailedCarTestDriveOfflineParams): boolean {
  return !values.name || !values.phone || !values.dealer;
}

function Request({
  onChange,
  onBlur,
  errors,
  values,
  dealers,
  hasErrors,
  touched,
  isSubmitting,
  setFieldValue,
}: Props) {
  const [userAgreement, setUserAgreement] = useState(false);
  const [dealer, setDealer] = useState<OptionType>({ label: '', value: '' });

  useUpdateEffect(() => {
    setFieldValue('dealer', Number(dealer.value));
  }, [dealer]);

  return (
    <Component>
      <S.FormRow>
        <TextInput
          id="name"
          name="name"
          label="Имя"
          placeholder="Имя"
          value={values.name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isSubmitting}
          errorMessage={touched?.name && errors.name ? ' ' : undefined}
        />
        <TextInput
          name="phone"
          label="Телефон"
          placeholder="+375 (__) ___-__-__"
          value={values.phone}
          onChange={(event) => {
            setFieldValue('phone', event.target.value);
          }}
          onBlur={onBlur}
          disabled={isSubmitting}
          errorMessage={touched?.phone && errors.phone ? ' ' : undefined}
        />
      </S.FormRow>

      <S.FormRow>
        <Select
          label="Автоцентр"
          value={dealer}
          options={dealers}
          onChange={setDealer}
          errorMessage={
            touched?.dealer && errors.dealer ? errors.dealer : undefined
          }
        />
      </S.FormRow>

      <S.FormRow>
        <CheckboxAgreement
          checked={userAgreement}
          onChange={() => setUserAgreement(!userAgreement)}
        />
      </S.FormRow>
      <S.Buttons>
        <S.StyledNextButton
          isLoading={isSubmitting}
          type="submit"
          disabled={hasErrors || checkValues(values) || !userAgreement}
        >
          {!isSubmitting ? 'Отправить' : <Loader color="#fff" />}
        </S.StyledNextButton>
      </S.Buttons>
    </Component>
  );
}

export default Request;

const Component = styled(S.Component)``;
