import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import Checkbox from '../Checkbox';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  count?: number;
  icon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  isModal?: boolean;
}

function FilterFieldCheckbox({
  isModal,
  label,
  count,
  icon,
  suffixIcon,
  checked,
  ...other
}: Props) {
  return (
    <Label isModal={isModal}>
      <Checkbox checked={checked} {...other} />
      {label && (
        <Content isSuffixIcon={Boolean(suffixIcon)}>
          <Text isChecked={checked}>{label}</Text> {icon && <Icon>{icon}</Icon>}{' '}
          {count && count !== 0 ? (
            <Count isChecked={checked}>({count})</Count>
          ) : null}
        </Content>
      )}
      {suffixIcon && (
        <SuffixIcon isChecked={!!checked}>{suffixIcon}</SuffixIcon>
      )}
    </Label>
  );
}

export default FilterFieldCheckbox;

const Label = styled.label<{ isModal?: boolean }>`
  display: flex;
  align-items: center;

  cursor: pointer;

  padding-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ isModal }) =>
    isModal &&
    css`
      padding-bottom: 0;
    `}
`;

const Content = styled.div<{ isSuffixIcon: boolean }>`
  margin-left: 7px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;

  ${({ isSuffixIcon }) =>
    isSuffixIcon &&
    css`
      margin-right: 7px;
    `}
`;

const Text = styled.span<{ isChecked?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${(props) => props.theme.black100};

  ${({ isChecked }) =>
    isChecked &&
    css`
      font-weight: 500;
    `}
`;

const Count = styled.span<{ isChecked?: boolean }>`
  margin-left: 5px;
  color: #aaa;
  display: none;

  ${({ isChecked }) =>
    isChecked &&
    css`
      color: ${(props) => props.theme.black100};
    `}
`;

const Icon = styled.span`
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
`;

const SuffixIcon = styled.span<{ isChecked: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  svg {
    circle {
      fill: ${(props) => (props.isChecked ? colors.main : colors.gray1000)};
      stroke: ${(props) => (props.isChecked ? colors.main : colors.gray1000)};
    }

    path {
      fill: ${(props) => (props.isChecked ? colors.main : colors.gray1000)};
    }
  }
`;
