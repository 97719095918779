import { OptionType } from '@/typings/common';

export enum SortTypes {
  Priority = 'priority',
  PaymentAmount = 'payment_amount',
}

export const sortData: OptionType[] = [
  {
    value: SortTypes.Priority,
    label: 'приоритету',
  },
  {
    value: SortTypes.PaymentAmount,
    label: 'размеру платежа ↑',
  },
];
