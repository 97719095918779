import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from '@tager/web-core';

import { AppState } from '@/store/store';

interface StateType {
  view: string;
  loading: boolean;
  status: FetchStatus;
}

const initialState: StateType = {
  view: 'HOME',
  loading: false,
  status: 'IDLE',
};

const slice = createSlice({
  name: 'selections',
  initialState,
  reducers: {
    setSellCarView(state, action: PayloadAction<string>) {
      state.view = action.payload;
    },
    setSellCarLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export default slice.reducer;

export const { setSellCarView, setSellCarLoading } = slice.actions;

export function selectSellCarView(state: AppState): string {
  return state.sellCar.view;
}

export function selectSellCarLoading(state: AppState): boolean {
  return state.sellCar.loading;
}
