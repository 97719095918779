import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

interface Props {
  label: string;
}

function InfoMessage({ label }: Props) {
  return (
    <Container>
      <Content>{label}</Content>
    </Container>
  );
}

export default InfoMessage;

const Container = styled.div`
  top: -35px;
  left: 0;
  position: absolute;
  z-index: 10;
`;

const Content = styled.span`
  font-size: 13px;
  display: block;
  padding: 10px 20px;
  background-color: ${colors.white};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;
`;
