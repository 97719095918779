import React from 'react';
import styled, { css } from 'styled-components';

import CheckboxTick from '@/assets/svg/checkbox-tick.svg';
import { colors } from '@/constants/theme';

type Props = {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
};

const Checkbox = ({ label, checked, disabled, onChange, className }: Props) => {
  return (
    <Component className={className} disabled={disabled}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      <Label>
        <CheckboxTick />
        <Inner dangerouslySetInnerHTML={{ __html: label }} />
      </Label>
    </Component>
  );
};

export default Checkbox;

const Inner = styled.div``;

export const Label = styled.div`
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => props.theme.blue100};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 0;

    width: 16px;
    height: 16px;
    border-radius: 5px;
    background: ${colors.white};
    border: 1px solid ${colors.gray400};
    transition: 0.3s all ease;
  }

  a {
    position: relative;
    text-decoration: none;
    color: ${colors.main};

    &:hover {
      color: ${colors.main100};
    }
  }

  svg {
    position: absolute;
    opacity: 0;
    transition: 0.3s all ease;

    width: 13px;
    height: 13px;
    left: 2px;
    top: 3px;
  }
`;

const Component = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-flex;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    cursor: inherit;

    &:checked + div {
      &:before {
        background: ${colors.main};
        border-color: ${colors.main};
      }
      svg {
        opacity: 1;
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;
