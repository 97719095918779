import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getSEOTemplate } from '@/services/requests';
import { SEOTemplate, SEOTemplatesTypes } from '@/typings/model';

interface State {
  status: FetchStatus;
  data: Nullable<SEOTemplate>;
}

const initialState: State = {
  status: 'IDLE',
  data: null,
};

const slice = createSlice({
  name: 'seo-template',
  initialState,
  reducers: {
    fetchSeoTemplateLoading: (state) => {
      state.status = 'LOADING';
    },
    fetchSeoTemplateSuccess: (state, action: PayloadAction<SEOTemplate>) => {
      state.status = 'SUCCESS';
      state.data = action.payload;
    },
    fetchSeoTemplateFailure: (state) => {
      state.status = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  fetchSeoTemplateLoading,
  fetchSeoTemplateSuccess,
  fetchSeoTemplateFailure,
} = slice.actions;

/** Thunks **/

export const fetchSeoTemplateThunk =
  (type: SEOTemplatesTypes): AppThunk<Promise<SEOTemplate>> =>
  async (dispatch) => {
    dispatch(fetchSeoTemplateLoading());
    try {
      const { data } = await getSEOTemplate(type);
      dispatch(fetchSeoTemplateSuccess(data));

      return data;
    } catch (error) {
      dispatch(fetchSeoTemplateFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectSeoTemplate = (state: AppState): Nullable<SEOTemplate> =>
  state.seoTemplate.data;
