import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import CheckedIcon from '@/assets/svg/checked-radio-btn.svg';
import UnCheckedIcon from '@/assets/svg/unchecked-radio-btn.svg';
import LocationIcon from '@/assets/svg/location.svg';
import PlaceholderTestDriveLocation from '@/assets/images/application-modal/placeholder-test-drive.png';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

interface Props {
  name: string;
  address: string;
  phone: string;
  image?: ThumbnailType;
  latitude?: string;
  longitude?: string;
  url?: string;

  checked: boolean;
  onClick: () => void;

  hiddenCheckedIcon?: boolean;
  isOutsideTestDrive?: boolean;
  className?: string;
}

function LocationCard({
  checked,
  onClick,
  address,
  phone,
  image,
  name,
  hiddenCheckedIcon,
  isOutsideTestDrive,
  latitude,
  longitude,
  className,
}: Props) {
  return (
    <Component className={className}>
      <Image onClick={onClick}>
        <CheckBoxWrapper isChecked={checked}>
          {hiddenCheckedIcon ? null : checked ? (
            <CheckedIcon />
          ) : (
            <UnCheckedIcon />
          )}
        </CheckBoxWrapper>
        <StyledPicture
          loading="lazy"
          isPlaceHolderImage={Boolean(image)}
          {...{
            ...convertThumbnailToPictureProps(image),
            src: image?.url ?? PlaceholderTestDriveLocation,
          }}
        />
      </Image>
      <LocationInfo>
        <LocationTitle>{name ? name : 'Временный заголовок'}</LocationTitle>
        <LocationAddress>{address}</LocationAddress>
        <PhoneAndMapContainer>
          {!isOutsideTestDrive && (
            <LocationLink
              to={`http://www.google.com/maps/place/${latitude},${longitude}`}
              target="_blank"
            >
              <LocationIconWrapper>
                <LocationIcon />
              </LocationIconWrapper>
              <LocationLinkText>Показать на карте</LocationLinkText>
            </LocationLink>
          )}

          <LocationPhone to={getPhoneLink(phone)}>{phone}</LocationPhone>
        </PhoneAndMapContainer>
      </LocationInfo>
    </Component>
  );
}

const positionStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Image = styled.div`
  position: relative;
  padding-top: 60%;
  background: ${colors.gray200};
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`;

const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneAndMapContainer = styled.div`
  margin-top: auto;
  padding-top: 10px;
  position: relative;
`;

const LocationIconWrapper = styled.div``;

const StyledPicture = styled(Picture)<{ isPlaceHolderImage?: boolean }>`
  position: static !important;

  img {
    object-fit: ${(props) => (props.isPlaceHolderImage ? 'cover' : 'contain')};
    width: 100%;
    height: 100%;
    ${positionStyles}
  }
`;

const CheckBoxWrapper = styled.div<{ isChecked?: boolean }>`
  position: absolute;
  left: 4px;
  top: 4px;
  z-index: 2;
  cursor: pointer;

  svg {
    position: relative;
    z-index: 2;

    ${({ isChecked }) =>
      isChecked &&
      css`
        rect:nth-child(2) {
          fill: ${colors.main};
        }
      `}
  }
`;

const LocationTitle = styled.span`
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.black};
`;

const LocationAddress = styled.span`
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black};
`;

const LocationPhone = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.main};
`;

const LocationLink = styled(LocationPhone)`
  display: flex;

  svg {
    margin-right: 4px;

    path {
      fill: ${colors.main};
    }
  }
`;

const LocationLinkText = styled.span`
  position: relative;

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    bottom: 6.5px;
    background: ${colors.main};
  }
`;

const Component = styled.div`
  position: relative;
  max-width: 160px;
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }

  ${media.mobile(css`
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    padding: 8px;
    border-radius: 8px;
    background: ${(props) => props.theme.gray_1};
    margin-top: 10px;
    margin-right: 0;

    ${Image} {
      padding-top: 0;
      min-width: 80px;
      height: 74px;
    }

    ${LocationTitle} {
      margin-top: 0;
    }

    ${LocationInfo} {
      margin-left: 10px;
    }
  `)}
`;

export default LocationCard;
