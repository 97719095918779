import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { loadBlogPost } from '@/services/requests';
import { BlogCategory, BlogSinglePost } from '@/typings/model';

interface State {
  singlePostStatus: FetchStatus;
  singlePostData: Nullable<BlogSinglePost>;
}

const initialState: State = {
  singlePostStatus: 'IDLE',
  singlePostData: null,
};

const slice = createSlice({
  name: 'single-post',
  initialState,
  reducers: {
    fetchSinglePostLoading: (state) => {
      state.singlePostStatus = 'LOADING';
    },
    fetchSinglePostSuccess: (state, action: PayloadAction<BlogSinglePost>) => {
      state.singlePostStatus = 'SUCCESS';
      state.singlePostData = action.payload;
    },
    fetchSinglePostFailure: (state) => {
      state.singlePostStatus = 'FAILURE';
    },
  },
});

export default slice.reducer;

export const {
  fetchSinglePostLoading,
  fetchSinglePostSuccess,
  fetchSinglePostFailure,
} = slice.actions;

/** Thunks **/

export const fetchSinglePostThunk =
  (
    category: BlogCategory,
    alias: string
  ): AppThunk<Promise<Nullable<BlogSinglePost>>> =>
  async (dispatch) => {
    dispatch(fetchSinglePostLoading());
    try {
      const postData = await loadBlogPost(category, alias);
      dispatch(fetchSinglePostSuccess(postData));
      return postData;
    } catch (error) {
      dispatch(fetchSinglePostFailure());
      return Promise.reject(error);
    }
  };

/** Selectors **/

export const selectSinglePost = (state: AppState) =>
  state.singlePost.singlePostData;
