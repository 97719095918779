import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { CheckboxAgreement } from '@/components/Checkbox';
import Loader from '@/components/Loader';
import CloseButton from '@/components/modals/shared/CloseButton';
import SuccessForm from '@/components/Forms/SuccessForm';
import TextInput from '@/components/TextInput';
import TextArea from '@/components/TextArea';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import Select from '@/components/Select';
import { OptionType } from '@/typings/common';

import {
  getResultCallbackFormFields,
  initialCallbackFormValues,
  validationCallbackFormSchema,
} from './CallbackForm.helpers';
import {
  CallbackFormFieldsSchema,
  CallbackFormProps,
} from './CallbackForm.types';

function CallbackForm({ closeModal, innerProps }: CallbackFormProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [dealer, setDealer] = useState<OptionType>({
    label: '',
    value: '',
  });

  const { title, fieldsConfig, request } = innerProps;

  useLockBodyScroll();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    isSubmitting,
  } = useFormik<CallbackFormFieldsSchema>({
    initialValues: initialCallbackFormValues,
    validationSchema: validationCallbackFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await request?.(getResultCallbackFormFields(values, fieldsConfig)).then(
          () => setFormSend(true)
        );
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<CallbackFormFieldsSchema>;
        setErrors(errorMap);
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (!fieldsConfig?.dealers?.length) {
      setFieldValue('dealer', { label: 'default', value: 'default' });
    }
  }, []);

  useUpdateEffect(() => {
    setFieldValue('dealer', dealer);
  }, [dealer]);

  return (
    <Component
      isFormSend={isFormSend}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <CloseButton onClick={closeModal} />

      {!isFormSend ? (
        <Content>
          <Title>{title}</Title>
          <InputsContainer>
            <InputRow>
              <TextInput
                id="name"
                name="name"
                label="Имя"
                placeholder="Имя"
                value={values.name}
                onChange={handleChange}
                errorMessage={
                  errors.name && touched.name ? errors.name : undefined
                }
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="phone"
                name="phone"
                label="Телефон"
                placeholder="+375 (__) ___-__-__"
                value={values.phone}
                onChange={handleChange}
                errorMessage={
                  errors.phone && touched.phone ? errors.phone : undefined
                }
              />
            </InputRow>

            {fieldsConfig?.dealers && fieldsConfig.dealers.length !== 0 && (
              <InputRow>
                <Select
                  label="Автоцентр"
                  value={dealer}
                  options={fieldsConfig.dealers}
                  onChange={setDealer}
                  errorMessage={
                    errors.dealer?.value && touched.dealer?.value
                      ? errors.dealer.value
                      : undefined
                  }
                />
              </InputRow>
            )}

            {fieldsConfig?.withMessage && (
              <InputRow>
                <TextArea
                  id="message"
                  name="message"
                  label="Комментарий"
                  placeholder="Комментарий"
                  value={values.message}
                  onChange={handleChange}
                />
              </InputRow>
            )}

            <Buttons>
              <StyledButton onClick={closeModal} isWhiteBackground>
                Отмена
              </StyledButton>
              <StyledButton
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                {!isSubmitting ? 'Отправить' : <Loader />}
              </StyledButton>
            </Buttons>

            <CheckboxAgreementWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxAgreementWrapper>
          </InputsContainer>
        </Content>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default CallbackForm;

const Component = styled.form<{ isFormSend?: boolean }>`
  display: flex;
  justify-content: center;
  min-width: 674px;
  padding: 25px 40px;
  border-radius: 10px;
  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    min-width: auto;
    padding: 20px;
  `)}

  ${({ isFormSend }) =>
    isFormSend &&
    css`
      ${media.mobile(css`
        align-items: center;
      `)}
    `}
`;

const Content = styled.div`
  max-width: 332px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 30px;
  `)};
`;

const InputRow = styled.div`
  &:not(:first-child) {
    margin-top: 35px;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const StyledButton = styled(Button)<{ isWhiteBackground?: boolean }>`
  position: relative;
  width: 100%;
  height: 48px;
  padding: 14px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-radius: 14px;
  background: ${colors.main};
  color: ${colors.white};
  transition: 0.3s;

  &:disabled {
    color: ${(props) => props.theme.black100};
    background: ${(props) => props.theme.gray400_1};
    border: 1px solid ${(props) => props.theme.gray400_2};
    opacity: 0.35;

    &:hover {
      background: ${(props) => props.theme.gray400_1};
    }
  }

  &:hover {
    background: ${colors.main100};
  }

  &:not(:first-child) {
    margin-left: 12px;

    ${media.mobile(css`
      margin-left: 0;
      margin-bottom: 15px;
    `)}
  }

  ${({ isWhiteBackground }) =>
    isWhiteBackground &&
    css`
      color: ${(props) => props.theme.black100};
      background: transparent;
      border: 1px solid ${(props) => props.theme.black_alpha20};

      &:hover {
        background: transparent;
        opacity: 0.7;
      }
    `}
`;

const CheckboxAgreementWrapper = styled.div`
  margin-top: 14px;
`;
