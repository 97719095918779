import moment from 'moment/moment';
import { range } from 'lodash';

import { StringField } from '@/typings/model';
import { OptionType } from '@/typings/common';

export function getCurrentDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
}

export function getTomorrowDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
}

export function getDayAfterTomorrowDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 2
  );
}

export function transformDate(date: StringField) {
  if (!date) {
    return null;
  }

  return date.split('-').reverse().join('.');
}

export function formatDate(date: Date | null) {
  if (!date) {
    return '';
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  day = day < 10 ? Number('0' + day) : day;
  month = month < 10 ? Number('0' + month) : month;

  return `${year}-${month}-${day}`;
}

export const getFirstDateOfYear = (): string =>
  moment().startOf('year').format('YYYY-MM-DD');

export const getLastDateOfYear = (): string =>
  moment().endOf('year').format('YYYY-MM-DD');

export const getYearsOptions = (): OptionType[] =>
  range(1990, new Date().getFullYear() + 1).map((year) => ({
    label: String(year),
    value: String(year),
  }));
