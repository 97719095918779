import React from 'react';

import { getTheme } from '@/constants/theme';

import * as S from './styles';
import { ButtonProps, AnchorProps, ButtonNextLinkProps } from './types';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const theme = getTheme();

    return (
      <S.StyledButton
        ref={ref}
        themeType={props.themeType ?? theme}
        {...props}
      />
    );
  }
);

export const ButtonLink = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    const theme = getTheme();

    return (
      <S.StyledLink ref={ref} themeType={props.themeType ?? theme} {...props} />
    );
  }
);

export const ButtonNextLink = React.forwardRef<
  HTMLAnchorElement,
  ButtonNextLinkProps
>((props, ref) => {
  const theme = getTheme();

  return (
    <S.StyledNextLink
      ref={ref}
      themeType={props.themeType ?? theme}
      {...props}
    />
  );
});

export default Button;
