import { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const ButtonStyles = css`
  color: ${colors.white};
  background-color: ${colors.main};
  border: 1px solid;
  border-color: ${colors.main};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.main100};
    border-color: ${colors.main100};
  }

  &:focus {
    color: ${colors.white};
    background-color: ${colors.main100};
    border-color: ${colors.main100};
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }

  &:active,
  &.active {
    color: ${colors.white};
    background-color: ${colors.main100};
    border-color: ${colors.main100};
  }

  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }

  &:disabled,
  &.disabled {
    color: ${colors.white};
    background-color: ${colors.main100};
    border-color: ${colors.main100};
  }
`;

export const ButtonWarningStyles = css`
  color: ${colors.black};
  background-color: #ffc107;
  border-color: #ffc107;

  &:hover {
    color: ${colors.black};
    background-color: #ffca2c;
    border-color: #ffc720;
  }

  &:focus {
    color: ${colors.black};
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }

  &:active,
  &.active {
    color: ${colors.black};
    background-color: #ffcd39;
    border-color: #ffc720;
  }

  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }

  &:disabled,
  &.disabled {
    color: ${colors.black};
    background-color: #ffc107;
    border-color: #ffc107;
  }
`;
