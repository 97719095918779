import React from 'react';
import styled, { css } from 'styled-components';

import WhiteTick from '@/assets/svg/checkbox-tick.svg';
import { colors } from '@/constants/theme';

import { StockSpecialFilterCheckboxProps } from './types';

function StockSpecialFilterCheckbox({
  option,
  checked,
  onChange,
  icon,
  isMobile,
}: StockSpecialFilterCheckboxProps) {
  return (
    <Component isMobile={isMobile}>
      <ListItem isMobile={isMobile}>
        <Input
          type="checkbox"
          checked={checked}
          onChange={() => onChange && onChange(option)}
          isMobile={isMobile}
        />
        <CheckboxFilter>
          {isMobile || (
            <Checkbox className="checkbox">
              <WhiteTick />
            </Checkbox>
          )}
          <LabelWrapper isMobile={isMobile}>{option.label}</LabelWrapper>
          <IconWrapper>{icon}</IconWrapper>
        </CheckboxFilter>
        {isMobile && (
          <Checkbox className="checkbox">
            <WhiteTick />
          </Checkbox>
        )}
      </ListItem>
    </Component>
  );
}

export default StockSpecialFilterCheckbox;

const Component = styled.label<{ isMobile?: boolean }>`
  display: flex;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: block;
    `}
`;
const CheckboxFilter = styled.div`
  display: flex;
  align-items: center;
`;

const LabelWrapper = styled.div<{ isMobile?: boolean }>`
  padding: 0 10px;
  font-size: 16px;
  color: ${(props) => props.theme.black};

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 10px 0 0;
    `}
`;

const IconWrapper = styled.div`
  svg {
    width: 14px;
    height: 16px;
  }
`;

const Input = styled.input<{ isMobile?: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;

  &:checked + div {
    .checkbox {
      background-color: ${colors.main};
      border-color: ${colors.main};
      transition: 0.3s all ease;

      svg {
        opacity: 1;
      }
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      &:checked + div + div {
        background-color: ${colors.main};
        border-color: ${colors.main};
        transition: 0.3s all ease;

        svg {
          opacity: 1;
        }
      }
    `}
`;

const ListItem = styled.div<{ isMobile?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-right: 60px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 18px 20px;
      border-bottom: 1px solid ${(props) => props.theme.gray};

      &:not(:last-child) {
        margin: 0;
      }
    `}
`;

const Checkbox = styled.div`
  cursor: pointer;
  position: relative;
  width: 18px;
  height: 18px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray400};
  border-radius: 5px;
  transition: 0.3s all ease;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    height: 15px;
    opacity: 0;
  }
`;
