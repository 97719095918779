import { ApiService } from '@tager/web-core';

import { CarsStockType, CarsType } from '@/typings/common';

import {
  CarsStockFiltersParams,
  CarsStockFiltersParamsResponse,
  CarsStockListParams,
  CarsStockListResponse,
  CarStockItemResponse,
  DetailedCarData,
  SelectedFiltersPayload,
  SimilarCarsDataParams,
} from './typings';

const requestStock = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_STOCK_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_STOCK_SERVICE_URL,
  },
}).getRequest();

/** Detailed Car **/

export function fetchDetailedCarData(
  carType: CarsType,
  carId: string
): Promise<DetailedCarData> {
  return requestStock.get({ path: `/${carType}/${carId}` });
}

export function fetchSimilarCarsData(
  carType: CarsType,
  carId: string,
  params?: SimilarCarsDataParams
): Promise<CarStockItemResponse[]> {
  return requestStock.get({ path: `/${carType}/${carId}/similar`, params });
}

export function getCarsStockList(
  carType: CarsStockType,
  params?: CarsStockListParams,
  filters?: SelectedFiltersPayload,
  signal?: AbortSignal
): Promise<CarsStockListResponse> {
  return requestStock.post({
    path: `/search`,
    body:
      carType === 'all'
        ? { ...params, ...filters }
        : { type: carType, ...params, ...filters },
    fetchOptions: { signal },
  });
}

export function getCarsStockFiltersParams(
  carType: CarsStockType,
  params?: CarsStockFiltersParams
): Promise<CarsStockFiltersParamsResponse> {
  return requestStock.get({
    path: carType === 'all' ? '/params' : `/params/${carType}`,
    params,
  });
}
