import { css } from 'styled-components';

export const LabelStaticStyles = css`
  top: -18px;
  left: 8px;
  text-transform: uppercase;
  transform: none;
  font-size: 12px;
  line-height: 15px;
`;
