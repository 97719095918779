import { ApiService } from '@tager/web-core';

import {
  AlfaBankLeasingQuizFormParams,
  CalculatorInitialValuesResponse,
  PaymentsScheduleParams,
  PaymentsScheduleResponse,
  ProductCardsData,
  ProductCardsParams,
  StaffPaymentsScheduleResponse,
  StaffProductCardsParams,
  StaffProductsCardsData,
} from './typings';

const request = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_FINANCE_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_FINANCE_SERVICE_URL,
  },
}).getRequest();

export function loadProductCardsData(
  params: ProductCardsParams
): Promise<ProductCardsData> {
  return request.get({
    path: `/calculator`,
    params,
  });
}

export function loadPaymentsScheduleData(
  params: PaymentsScheduleParams
): Promise<PaymentsScheduleResponse[]> {
  return request.get({
    path: `/products/${params.id}/calendar`,
    params: params.params,
  });
}

export function loadCalculatorInitialValues(
  car: string
): Promise<CalculatorInitialValuesResponse> {
  return request.get({
    path: `/calculator/init`,
    params: { car },
  });
}

export function submitAlfaLeasingQuiz(
  params: AlfaBankLeasingQuizFormParams
): Promise<{ success: boolean }> {
  return request.post({
    path: `/products/${params.id}/alfabank-quiz`,
    body: params.params,
  });
}

export function loadStaffProductCardsData(
  params: StaffProductCardsParams
): Promise<StaffProductsCardsData> {
  return request.get({
    path: '/staff-calculator',
    params,
  });
}

export function loadStaffPaymentsScheduleData(
  params: PaymentsScheduleParams
): Promise<StaffPaymentsScheduleResponse[]> {
  return request.get({
    path: `/staff-products/${params.id}/calendar`,
    params: params.params,
  });
}
