import { combineReducers } from 'redux';

import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';
import authReducer from './auth';
import sellCarReducer from './sell-car';
import onlineValuationUserData from './online-valuation-user-data';
import rateCarReducer from './rate-car-data';
import offlineValuationUserData from './offline-valuation-user-data';
import ampLandingReducer from './amp-landing';
import layoutReducer from './layout';
import dealersReducer from './dealers';
import singleDealerReducer from './single-dealer';
import postsListReducer from './posts-list';
import singlePostReducer from './single-post';
import purchaseReducer from './purchase';
import bannersReducer from './banners';
import cabinetReducer from './cabinet';
import singleModelCarReducer from './single-model-car';
import staffCalculatorReducer from './staff-calculator';
import catalogReducer from './catalog';
import detailedCarReducer from './detailed-car';
import carsStockReducer from './cars-stock';
import carsStockCompareReducer from './cars-stock-compare';
import serviceReducer from './service';
import seoTemplateReducer from './seo-template';

const tagerReducer = combineReducers({
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  layout: layoutReducer,
  auth: authReducer,
  ampLanding: ampLandingReducer,
  sellCar: sellCarReducer,
  rateCarData: rateCarReducer,
  onlineValuationUserData: onlineValuationUserData,
  offlineValuationUserData: offlineValuationUserData,
  dealers: dealersReducer,
  singleDealer: singleDealerReducer,
  postsList: postsListReducer,
  singlePost: singlePostReducer,
  purchase: purchaseReducer,
  banners: bannersReducer,
  cabinet: cabinetReducer,
  singleModelCar: singleModelCarReducer,
  staffCalculator: staffCalculatorReducer,
  catalog: catalogReducer,
  detailedCar: detailedCarReducer,
  carsStock: carsStockReducer,
  carsStockCompare: carsStockCompareReducer,
  service: serviceReducer,
  seoTemplate: seoTemplateReducer,
});

export default rootReducer;
