import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Nullable, useMedia } from '@tager/web-core';

import { usePageType } from '@/hooks/usePageType';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { getSiteNavigationMicromarking } from '@/utils/seo';
import { handleScrollToDomElement } from '@/utils/scroll';
import {
  selectMobileMenuOpened,
  selectMobileUserOpened,
  setMobileMenuOpened,
  setMobileUserOpened,
} from '@/store/reducers/layout';

export const useMobileMenuCloseOnRouteChange = () => {
  const router = useRouter();
  const dispatch = useTypedDispatch();

  const isMobile = useMedia('(max-width: 1259px)');
  const isMobileMenuOpened = useTypedSelector(selectMobileMenuOpened);
  const isMobileUserOpened = useTypedSelector(selectMobileUserOpened);

  useEffect(() => {
    const isMenuOpened = isMobileMenuOpened || isMobileUserOpened;

    if (!isMobile || !isMenuOpened) {
      return;
    }

    const handleRouteChange = () => {
      if (isMobileMenuOpened) {
        dispatch(setMobileMenuOpened(false));
      }

      if (isMobileUserOpened) {
        dispatch(setMobileUserOpened(false));
      }

      document.body.style.overflow = 'auto';
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [
    dispatch,
    isMobile,
    isMobileMenuOpened,
    isMobileUserOpened,
    router.events,
  ]);
};

export const useDealerWebLinkClick = () => {
  const router = useRouter();
  const { template } = usePageType();

  function handleClickLink(
    link: Nullable<string>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    if (!link) {
      return;
    }

    const dealerTemplates = [
      'b2b-home',
      'hisun-home',
      'jac-home',
      'sollers-home',
      'jetour-home',
      'zeekr-home',
    ];
    const isDealerTemplate = dealerTemplates.includes(template);

    if (link.startsWith('#')) {
      event.preventDefault();

      if (isDealerTemplate) {
        if (router.asPath !== '/') {
          router.replace('/');
        }

        handleScrollToDomElement(link.substring(1));
      } else {
        router.push(`/${link}`);
      }
    }
  }

  return { handleClickLink };
};

export function useSiteNavigationMicromarking(): string {
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];
  const navs = headerMenu.map(({ label, link }) => ({
    name: label,
    url: link ?? '',
  }));

  return getSiteNavigationMicromarking(navs);
}
