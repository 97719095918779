import React from 'react';
import styled, { css } from 'styled-components';

import { BaseApplicationOption } from '@/components/modals/DetailedCarModal/components/Application/common.types';
import { media } from '@/utils/mixin';
import { ApplicationModalType } from '@/components/modals/DetailedCarModal/DetailedCarModal';

import FilterFieldCheckbox from './components/FilterFieldCheckbox';

interface Props {
  options: Array<BaseApplicationOption>;
  applicationModalType: ApplicationModalType;
  onChange: (option: BaseApplicationOption) => void;
}

function RegistrationType({ options, onChange, applicationModalType }: Props) {
  return (
    <Component applicationModalType={applicationModalType}>
      <Content>
        {options.map((option, index) => (
          <Item key={index} onClick={() => onChange(option)}>
            <FilterFieldCheckbox {...option} isModal={true} />
          </Item>
        ))}
      </Content>
    </Component>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  label {
    pointer-events: none;
  }

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Item = styled.div`
  padding: 15px;
  background: ${(props) => props.theme.gray_1};
  border-radius: 5px;
  cursor: pointer;

  div {
    margin-bottom: 0;
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }

  ${media.mobile(css`
    width: 100% !important;
    margin-top: 10px;
  `)}
`;

const Component = styled.div<{ applicationModalType: ApplicationModalType }>`
  margin-top: 20px;

  ${Item} {
    width: calc(50% - 12px);
  }

  ${(props) =>
    props.applicationModalType === 'CUSTOM_PRICE' &&
    css`
      ${Item} {
        &:last-child {
          display: none;
        }
      }
    `}
`;

export default RegistrationType;
