import { gtag } from '@tager/web-analytics';

export type GoogleEventParamVariant =
  | 'car'
  | 'stockStatus'
  | 'h1_brand'
  | 'h1_model'
  | 'year_manufactured';

export type GoogleEventParamType = Partial<
  Record<GoogleEventParamVariant, string>
>;

export function googleEvent(event: string, params?: GoogleEventParamType) {
  console.log(
    `Google Event: ${event}${
      params ? `\nParams: ${JSON.stringify(params)}` : ''
    }`
  );
  gtag.event(event, params);
}
