import React from 'react';

import StockSpecialFilterCheckbox from '@/components/StockSpecialFilterCheckbox';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getSelectedFilters,
  getStockFilterParams,
  setCarOnStock,
  setPage,
} from '@/store/reducers/cars-stock';
import { OptionType } from '@/typings/common';
import { getSpecialFilterOptionById } from '@/modules/CarsStock/components/common.helpers';
import { CarsStockFlag } from '@/services/stock/enums';

export function OnStockFilter() {
  const dispatch = useTypedDispatch();
  const selectedOnStock =
    useTypedSelector(getSelectedFilters).selectedParams.selectedSpecials
      .selectedOnStock;
  const stockFilterParams = useTypedSelector(getStockFilterParams);

  const onStockOption = getSpecialFilterOptionById(
    stockFilterParams,
    CarsStockFlag.OnStock
  );

  const handleChangeOnStock = (onStock: OptionType) => {
    if (!selectedOnStock.value) {
      dispatch(setCarOnStock(onStock));
    } else {
      dispatch(setCarOnStock({ label: '', value: '' }));
    }
    dispatch(setPage(1));
  };

  return (
    <StockSpecialFilterCheckbox
      checked={!!selectedOnStock.value}
      option={onStockOption}
      onChange={handleChangeOnStock}
      isMobile
    />
  );
}
