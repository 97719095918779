import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const LabelStaticStyles = css`
  top: -18px;
  left: 8px;
  text-transform: uppercase;
  transform: none;
  font-size: 12px;
  line-height: 15px;
`;

export const Label = styled.span<{ hasValue: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;

  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.black100_alpha50};
  pointer-events: none;
  transition: all 0.2s;

  ${({ hasValue }) =>
    hasValue &&
    css`
      ${LabelStaticStyles}
    `}
`;

export const Placeholder = styled.span`
  display: none;
`;

export const TextInputStyles = css<{ invalid?: boolean }>`
  padding: 14px 16px;

  background: ${(props) => props.theme.white_1};
  border-radius: 5px;

  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black100};
  background-clip: padding-box;
  border: 1px solid ${(props) => props.theme.gray1800};
  appearance: none;

  &::placeholder {
    color: transparent;
    opacity: 1;
  }

  &:focus ~ ${Label} {
    ${LabelStaticStyles}
  }

  &:focus ~ ${Placeholder} {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;

    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

    color: ${(props) => props.theme.black100_alpha50};
    pointer-events: none;
    transition: all 0.2s;
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${colors.red700};
      background: ${(props) => props.theme.red_1};
    `}
`;
