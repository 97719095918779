import React from 'react';
import styled, { css } from 'styled-components';

import { formatNumber } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { CarPreviewProps } from './CarPreview.types';

function CarPreview({
  className,
  carType,
  image,
  name,
  currency,
  price,
  body,
}: CarPreviewProps) {
  return (
    <Component className={className}>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      <Content>
        <Info>
          <Title>{name}</Title>
          <Subtitle>
            {body && body + ','} {carType === 'new' ? 'новый' : 'с пробегом'}
          </Subtitle>
        </Info>
        {price ? (
          <Price>
            {formatNumber(price)} {currency}
          </Price>
        ) : null}
      </Content>
    </Component>
  );
}

export default CarPreview;

const Component = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background: ${(props) => props.theme.gray_1};
`;

const Image = styled(Picture)`
  width: 50px;
  height: 50px;
  background: ${colors.white};
  border-radius: 4px;

  ${media.mobile(css`
    width: 74px;
    height: 74px;
  `)}

  picture,
  img {
    border-radius: 4px;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 5px;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.black100};
`;

const Subtitle = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
  color: ${(props) => props.theme.black100_alpha50};
`;

const Price = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.black100};

  ${media.mobile(css`
    margin-top: 8px;
  `)}
`;
