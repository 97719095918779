import { CallbackFormFields } from '@/components/modals/CallbackForm/CallbackForm.types';
import {
  submitCarCatalog,
  submitFormCallback,
  submitPartnersCarsRequest,
} from '@/services/leads/leads-service';
import {
  CarCatalogParams,
  PartnersCarsRequestParams,
} from '@/services/leads/typings';
import { googleEvent, GoogleEventParamType } from '@/utils/events';

export async function handleSubmitCallbackForm(
  values: CallbackFormFields,
  event?: { type?: string; params?: GoogleEventParamType }
): Promise<void> {
  try {
    await submitFormCallback(values).then(() => {
      if (event?.type) {
        googleEvent(event.type, event.params);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export async function handleSubmitPartnersCarsRequestForm(
  values: PartnersCarsRequestParams,
  event?: { type?: string; params?: GoogleEventParamType }
): Promise<void> {
  try {
    await submitPartnersCarsRequest(values).then(() => {
      if (event?.type) {
        googleEvent(event.type, event.params);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export async function handleSubmitCarCatalogForm(
  values: CarCatalogParams,
  event?: { type?: string; params?: GoogleEventParamType }
): Promise<void> {
  try {
    await submitCarCatalog(values).then(() => {
      if (event?.type) {
        googleEvent(event.type, event.params);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}
