import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import rowDownIcon from '@/assets/svg/arrow-down.svg?url';
import { media } from '@/utils/mixin';

export const ScrollStyles = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.gray1600_alpha50};
    border: 1px solid ${(props) => props.theme.gray1300_alpha50_1};
    border-radius: 29px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.gray900};
    border-radius: 29px;
  }

  /* firefox */

  scrollbar-width: thin;
`;

export const DatePickerStyles = css`
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-inner-spin-button,
  input[type='time']::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
    opacity: 0;
  }

  .react-datepicker-popper {
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 0;
    z-index: 2000;
  }

  .react-datepicker {
    z-index: 10;
    width: 100%;
    border: 1px solid ${(props) => props.theme.gray1800};
    box-sizing: border-box;
    box-shadow: ${(props) => props.theme.blackShadow100};
    background: ${(props) => props.theme.white_1};

    &__triangle {
      display: none;
    }

    &__navigation {
      margin-top: 8px;

      &-icon {
        &::before {
          border-width: 2px 2px 0 0;
          border-color: ${(props) => props.theme.black};
        }
      }
    }

    &__week {
      display: flex;
      justify-content: space-between;
    }

    &__month-container {
      width: 100%;
    }

    &__current-month {
      margin-top: 5px;
      text-transform: capitalize;
      margin-bottom: 15px;
      color: ${(props) => props.theme.black};
    }

    &__day {
      box-sizing: initial;
      border: 1px solid ${(props) => props.theme.black100_alpha15};
      border-radius: 4px;
      padding: 0 5px;
      width: 2.7rem;
      color: ${(props) => props.theme.black};

      &:hover {
        opacity: 0.7;
        background: ${(props) => props.theme.white_1};
      }

      &-names {
        margin: 0 0.4rem;
        display: flex;
        justify-content: space-between;
      }

      &-name {
        text-transform: capitalize;
        box-sizing: initial;
        padding: 0 5px;
        color: ${(props) => props.theme.black};
      }

      &--keyboard-selected,
      &--selected {
        color: ${colors.white};
        border-color: transparent;
      }

      &--keyboard-selected {
        background: #2a87d0;

        &:hover {
          background: #2a87d0;
        }
      }

      &--selected {
        background: ${colors.main};

        &:hover {
          background: ${colors.main};
        }
      }

      &--disabled,
      &--outside-month {
        opacity: 0.5;
      }

      &--disabled {
        pointer-events: none;
        color: #ccc;
      }
    }

    &__header {
      font-weight: 700;
      font-size: 13px;
      line-height: 24px;
      background: ${(props) => props.theme.white_1};
      border: none;
      margin-bottom: -5px;
      padding-bottom: 0;
    }
  }

  .badge-field {
    background: transparent;
  }
`;

export const DatePickerWithSelectStyles = css`
  ${DatePickerStyles}

  .react-datepicker {
    &__current-month {
      display: none;
    }

    &__header__dropdown {
      margin-top: 5px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__month-dropdown-container,
    &__year-dropdown-container {
      position: relative;
      margin: 0 15px;
    }

    &__month-read-view,
    &__year-read-view {
      visibility: visible !important;

      &:hover {
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
          border-top-color: ${(props) => props.theme.black};
        }
      }

      &--down-arrow {
        border-width: 2px 2px 0 0;
        right: -15px;
        top: 5px;
        border-color: ${(props) => props.theme.black};
      }

      &--selected-month,
      &--selected-year {
        color: ${(props) => props.theme.black};
        font-size: 0.944rem;
        text-transform: capitalize;
      }
    }

    &__month-dropdown,
    &__year-dropdown {
      top: 27px;
      left: 0;
      width: auto;
      border: 1px solid ${(props) => props.theme.gray1800};
      box-shadow: ${(props) => props.theme.blackShadow100};
      background: ${(props) => props.theme.white_1};
      max-height: 220px;
      overflow: auto;

      ${ScrollStyles}
    }

    &__month-option,
    &__year-option {
      padding: 8px;
      font-weight: 500;
      font-size: 0.86rem;
      text-transform: capitalize;
      color: ${(props) => props.theme.black};

      &:hover {
        background: ${(props) => props.theme.gray300};
      }

      &--selected_month,
      &--selected_year {
        color: ${colors.white};
        background: ${colors.main};

        &:hover {
          background-color: ${colors.main100};
        }
      }

      span {
        display: none;
      }
    }

    &__navigation--years-previous,
    &__navigation--years-upcoming {
      position: relative;
      height: 16px;
      width: 16px;
      top: 0;
      margin-top: 0;
    }

    &__navigation--years-previous {
      background: url('${rowDownIcon}') no-repeat center;
    }

    &__navigation--years-upcoming {
      background: url('${rowDownIcon}') no-repeat center;
      transform: rotate(180deg);
    }
  }
`;

export const NavigationButton = styled.button<{
  next?: boolean;
  prev?: boolean;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 30px 20px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.15);
  transition: background-color 0.7s;
  line-height: 16px;
  z-index: 10;

  svg {
    width: 23px;
    height: 45px;
  }

  ${(props) =>
    props.next
      ? css`
          right: 50%;
          transform: translate(50%, -50%);

          svg {
          }
        `
      : null}
  ${(props) =>
    props.prev
      ? css`
          left: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(-180deg);
        `
      : null}
`;

export const NavButtonWrapper = styled.div<{ next?: boolean; prev?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.7s;
  z-index: 15;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);

    ${NavigationButton} {
      background: rgba(255, 255, 255, 0);
    }
  }

  ${media.mobile(
    css`
      display: none;
    `
  )}

  ${(props) =>
    props.next
      ? css`
          right: 0;
          padding-left: 4.82%;

          ${media.mobile(css`
            padding-left: 16%;
          `)}
        `
      : null}

  ${(props) =>
    props.prev
      ? css`
          padding-right: 4.82%;
          left: 0;

          ${media.mobile(css`
            padding-right: 16%;
          `)}
        `
      : null}
`;

export const QRCodeStyles = css`
  path:first-child {
    fill: transparent;
  }

  path:last-child {
    fill: ${(props) => props.theme.black};
  }
`;

export const HideScrollbar = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TextLimit = css<{ lines?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines ?? 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
